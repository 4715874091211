import React, { useState, useEffect } from 'react';
import { Button, Loader } from '@thd-olt-component-react/core-ui';
import { number, string } from 'prop-types';
import { useSubmitHelpful } from '../../../hooks/index';
import { dataModel } from '../dataModel';
import { triggerAnalytics } from '../../../Analytics';
import '../Voting.scss';

const Vote = (props) => {
  const { totalPositiveFeedbackCount, answerId } = props;

  const [voted, setVoted] = useState(false);

  const [buttonText, setButtonText] = useState('Helpful?');

  const postHelpfulData = {
    id: answerId,
    feedbacktype: 'helpfulness',
    contenttype: 'answer',
    vote: 'positive'
  };

  const [submitHelpful, helpfulResponse] = useSubmitHelpful(postHelpfulData);

  const handleHelpful = () => {
    submitHelpful();
    const payload = { component: 'was this helpful', element: 'upvote' };
    triggerAnalytics(payload);
  };

  useEffect(() => {
    if (helpfulResponse.loading) {
      setButtonText(<Loader active bttn />);
    }
  }, [helpfulResponse.loading]);

  useEffect(() => {
    if (helpfulResponse.data && !voted) {
      setVoted(true);
      setButtonText('Helpful');
    }
  }, [helpfulResponse.data]);

  return (
    <div className="vote-container">
      <Button
        className={`helpful-button ${helpfulResponse.called ? 'disabled' : ''}`}
        onClick={handleHelpful}
        title="Helpful"
        disabled={helpfulResponse.called}
        inline
        outline
        small
      >
        {buttonText}
      </Button>
      {totalPositiveFeedbackCount > 0 ? (
        <span className="helpful-count">
          {voted ? totalPositiveFeedbackCount + 1 : totalPositiveFeedbackCount}{' '}
          found this answer helpful
        </span>
      ) : (
        <span className="helpful-count">
          {voted
            ? `${totalPositiveFeedbackCount + 1} found this answer helpful`
            : null}
        </span>
      )}

    </div>
  );
};

Vote.dataModel = dataModel;

Vote.propTypes = {
  totalPositiveFeedbackCount: number,
  answerId: string.isRequired
};

Vote.defaultProps = {
  totalPositiveFeedbackCount: 0
};

Vote.displayName = 'Vote';

export { Vote };
