import React, { useState, Suspense } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { string, shape } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import { MainAsset } from '../MediaGalleryOverlay/components/MainAsset/MainAsset';
import Helper from '../../Helper';
import './media-highlight.scss';
import { mediaClick } from '../../../publisher';
import { dataModel } from '../../dataModel';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));

export const MediaHighlight = ({ itemId }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const { data, loading, error } = useDataModel('product', { variables: { itemId }, skip: !itemId });
  if ((error && !data) || loading || !data) {
    return null;
  }
  const { product } = data;
  const { identifiers, media } = product || {};
  const { images, video } = media || {};
  const { altText } = images;
  const brightcoveVideo = (video || []).find((v) => v.type === 'BRIGHTCOVE VIDEO');
  const lifeStyleImage = (images || []).find((i) => i.type === 'IMAGE_LIFE_STYLE'
  || i.type === 'IMAGE_LEFT_VIEW' || i.type === 'IMAGE_RIGHT_VIEW' || i.type === 'IMAGE_TOP_VIEW'
  || i.type === 'IMAGE');
  const alternateViewImage = (images || []).find((i) => i.type === 'IMAGE_ALTERNATE_VIEW');

  if (!(brightcoveVideo || lifeStyleImage || alternateViewImage)) {
    return null;
  }

  const { brandName, productLabel } = identifiers || {};
  const viewImage = lifeStyleImage || alternateViewImage;

  const mainAsset = viewImage ? Helper.normalizeImage({
    image: viewImage,
    size: '1000'
  }) : null;

  const alt = altText || `${productLabel} by ${brandName}`;

  return (
    <div className="media-highlight" data-component="MediaHighlight">
      {!brightcoveVideo
        && (
          <MainAsset
            mainAsset={mainAsset}
            zoomedAsset={mainAsset}
            onAssetClick={() => setOverlayOpen(true)}
            videoAnalyticsData={{ position: '1', section: 'media' }}
            itemId={itemId}
          />
        )}
      {brightcoveVideo
      && (
        <>
          <button
            className="media-highlight__play-button"
            type="button"
            onClick={() => {
              setOverlayOpen(true);
              mediaClick({ section: 'highlights', component: 'media', target: 'video' });
            }}
          >
            <div className="media-highlight__still-wrapper">
              <Image
                src={brightcoveVideo.videoStill}
                alt={brightcoveVideo.title}
                lazy
              />
              <span className="media-highlight__play-icon" />
            </div>
          </button>
          <div className="media-highlight__title">
            {brightcoveVideo.title}
          </div>
          <div className="media-highlight__description">
            {brightcoveVideo.shortDescription}
          </div>
        </>
      )}
      {overlayOpen
      && (
        <Suspense fallback={(<div />)}>
          <Overlay closeButton onClose={() => setOverlayOpen(!overlayOpen)} open={overlayOpen}>
            <div className="media-highlight__details">
              <div className="media-highlight__product-label">
                {productLabel}
              </div>
              <div className="media-highlight__brand">
                by&nbsp;
                <span className="media-highlight__brand--bold">
                  {brandName}
                </span>
              </div>
            </div>
            {!brightcoveVideo
          && (
            <div className="media-highlight__image">
              <Image
                src={mainAsset.url}
                alt={alt}
                lazy
              />
            </div>
          )}
            { brightcoveVideo
          && (
            <div className="media-highlight__video">
              <VideoPlayer
                videoId={brightcoveVideo.videoId}
                analyticsData={{ section: 'expanded-view', component: 'thumbnails', position: '1' }}
              />
            </div>
          )}
          </Overlay>
        </Suspense>
      )}
    </div>
  );

};

MediaHighlight.displayName = 'MediaHighlight';

MediaHighlight.propTypes = {
  itemId: string.isRequired
};

MediaHighlight.dataModel = dataModel;
