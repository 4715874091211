import React from 'react';

export const getHighlightedText = (Id, text, searchKeyWord) => {
  if (!searchKeyWord) {
    return text;
  }
  const parts = text.split(new RegExp(`(${searchKeyWord})`, 'gi'));
  return (
    <span key={`search_by_${searchKeyWord}`}>
      {parts.map((part, index) => (part.toLowerCase() === searchKeyWord.toLowerCase() ? (
        <span
          key={`search_keyword_${Id}_${index}`}
          style={{ backgroundColor: 'rgba(249, 99, 2, 0.3)' }}
        >
          {part}
        </span>
      ) : (
        part
      ))
      )}
    </span>
  );
};
