import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { TextField } from '@one-thd/sui-atomic-components';
import { notEmptyString, validEmail } from '../helper/QAModalFormHelper';

export const EmailAddress = ({
  emailAddress,
  validationError,
  setEmailAddress
}) => {
  const [errorText, setErrorText] = useState(null);

  const [status, setStatus] = useState(null);

  const handleChange = (event) => {
    const email = event.target.value;
    if (!notEmptyString(email)) {
      setErrorText('Please provide your email.');
      setStatus('error');
    } else if (!validEmail(email)) {
      setErrorText('Please enter a valid email.');
      setStatus('error');
    } else {
      setErrorText(null);
      setStatus('success');
    }
    setEmailAddress(email);
  };

  return (
    <div className="sui-mb-6">
      <TextField
        data-testid="emailAddress"
        fullWidth
        InputProps={{ inputProps: { maxLength: '255' } }}
        label="Email"
        onChange={handleChange}
        required
        status={validationError ? 'error' : status}
        statusMessage={validationError || errorText}
        value={emailAddress}
      />
    </div>
  );
};

EmailAddress.displayName = 'EmailAddress';

EmailAddress.propTypes = {
  emailAddress: string,
  validationError: string,
  setEmailAddress: func
};

EmailAddress.defaultProps = {
  validationError: '',
  emailAddress: '',
  setEmailAddress: null
};
