/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { string } from 'prop-types';

const ProductDetailsButton = ({ canonicalUrl }) => {
  if (!canonicalUrl) {
    return null;
  }
  return (
    <div className="[&_a]:hover:sui-no-underline" data-testid="bundle-product-details-button">
      <Button
        variant="secondary"
        href={canonicalUrl}
        id="PipButton"
      >
        View Full Product Details
      </Button>
    </div>
  );
};

ProductDetailsButton.propTypes = {
  canonicalUrl: string.isRequired
};

export { ProductDetailsButton };
