import React from 'react';
import { Typography, Link, RatingMeter } from '@one-thd/sui-atomic-components';
import {
  shape as shapeProp,
  number as propTypeNumber,
  string as propTypeString,
  func as propTypeFunc,
  bool as propTypeBool
} from 'prop-types';
import { onClickProductSpecifications } from '../../utils/product-bundle-utils';
import { getFuelType } from '../../utils/bundle-features-utils';

export const ProductListDetails = (props) => {
  const {
    product,
    openQuickView,
    isMobile,
    scrollStickyHeaderHeight,
    accordionName,
    setIsRatingOpen
  } = props;
  const {
    quickView, productLabel,
    productListFuelType,
    productListModelNumber,
    productListRating,
    productListViewSpecificationLink
  } = product?.features ?? {};
  const fuelType = productListFuelType && getFuelType(product);
  return (
    <div className="sui-w-full">
      {
        quickView ? (
          <Link
            variant="body-base"
            underline="hover"
            onClick={() => openQuickView(product)}
            data-testid="product-list-product-label"
          >
            <b>{product?.identifiers?.brandName}</b> <br />
            {product?.identifiers?.productLabel}
          </Link>
        ) : (
          <Typography variant="body-base" weight="bold">
            {product?.identifiers?.brandName}
          </Typography>
        )
      }
      {
        productLabel && (
          <Typography
            lineClamp="2"
            variant="body-base"
            data-testid="product-list-product-label"
          >
            {product?.identifiers?.productLabel}
          </Typography>
        )
      }
      {
        product?.quantity > 1 && (
          <Typography variant="body-base" data-testid="product-list-quantity">
            Qty: {product?.quantity}
          </Typography>
        )
      }
      {
        productListModelNumber && product?.identifiers?.modelNumber && (
          <Typography variant="body-xs" color="subtle">
            Model # {product.identifiers.modelNumber}
          </Typography>
        )
      }
      {
        fuelType && (
          <Typography variant="body-xs">
            <p className="sui-text-base sui-pt-3"> Fuel Type -
              <span className="sui-font-bold sui-pl-1">
                {fuelType}
              </span>
            </p>
          </Typography>
        )
      }
      {
        productListRating && product?.reviews?.ratingsReviews && (
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          <div className="sui-flex sui-pt-3 [&>*]:sui-gap-1">
            <RatingMeter
              data-testid="product-list-item-rating-reviews"
              className="hover:sui-cursor-pointer"
              value={parseFloat(product?.reviews?.ratingsReviews?.averageRating)}
              label={`(${product?.reviews?.ratingsReviews?.totalReviews})`}
              target="_blank"
              edge="start"
              RatingProps={{
                color: 'brand'
              }}
              onClick={() => {
                setIsRatingOpen(true);
                openQuickView(product);
              }}
            />
          </div>
        )
      }
      {
        productListViewSpecificationLink && (
          <div className="sui-pt-3">
            <Link
              href={accordionName}
              underline="always"
              rel="noopener noreferrer"
              onClick={(event) => onClickProductSpecifications({
                event,
                product,
                accordionName,
                isMobile,
                scrollStickyHeaderHeight
              })}
            >
              View Item Specifications
            </Link>
          </div>
        )
      }
    </div>
  );
};

ProductListDetails.propTypes = {
  product: shapeProp({}),
  openQuickView: propTypeFunc.isRequired,
  isMobile: propTypeBool,
  scrollStickyHeaderHeight: propTypeNumber,
  accordionName: propTypeString,
  setIsRatingOpen: propTypeFunc.isRequired
};

ProductListDetails.defaultProps = {
  product: null,
  scrollStickyHeaderHeight: 0,
  accordionName: '',
  isMobile: false
};