import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
/**
 * Props
 *
 * @typedef {Object} Props
 * @property {string} itemId - The unique identifier of the item associated with the removal operation.
 * @property {Function} closeDrawer - A function called to close the drawer or modal component.
 * @property {Function} setSelectedItemId - A function to set the currently selected item ID in the state,
 * typically used to specify which item is being interacted with.
 * @property {string} [buttonText='Remove'] - The text displayed on the button. Defaults to "Remove".
 * @property {string} [buttonVariant='secondary'] - The visual style variant of the button. Defaults to "secondary".
 *
 * @param {Props} props The properties passed to the RemoveWithDrawer component.
 * @returns {React.ReactElement} A button component that, when clicked, performs a removal operation.
 */
export const RemoveWithDrawer = ({
  itemId,
  closeDrawer,
  buttonText = 'Remove',
  buttonVariant = 'secondary',
  setSelectedItemId,
  triggerAnalytics
}) => (
  <Button
    id={`remove-cta-${itemId}`}
    fullWidth
    variant={buttonVariant}
    data-testid="remove-cta-button"
    onClick={() => {
      setSelectedItemId(itemId);
      closeDrawer();
      triggerAnalytics();
    }}
  >
    {buttonText}
  </Button>
);

RemoveWithDrawer.propTypes = {
  itemId: PropTypes.string,
  closeDrawer: PropTypes.func,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
  setSelectedItemId: PropTypes.func.isRequired,
  triggerAnalytics: PropTypes.func.isRequired
};

RemoveWithDrawer.defaultProps = {
  itemId: undefined,
  closeDrawer: undefined,
  buttonText: 'Remove',
  buttonVariant: 'secondary'
};