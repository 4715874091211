import {
  arrayOf as arrayType,
  params,
  string as stringType,
  bool as booleanType,
  shape as shapeType,
  customType
} from '@thd-nucleus/data-sources';

const BVResponse = {
  Errors: arrayType(stringType()),
  SubmissionId: stringType(),
  AuthorSubmissionToken: stringType(),
  HasErrors: booleanType()
};

export const dataModel = {
  submitQuestion: params({
    itemId: stringType().isRequired(),
    questionSummary: stringType().isRequired(),
    userNickName: stringType(),
    userEmail: stringType().isRequired(),
    userId: stringType(),
    action: stringType().isRequired(),
    sendEmailAlertWhenPublished: stringType(),
    deviceFingerPrint: stringType(),
    photos: arrayType(customType('UGCSubmissionPhoto')
      .shape({
        url: stringType(),
        caption: stringType()
      }))
  }).mutation().shape(BVResponse),
  submitAnswer: params({
    questionId: stringType().isRequired(),
    answerText: stringType().isRequired(),
    userNickName: stringType(),
    userEmail: stringType().isRequired(),
    userId: stringType(),
    action: stringType().isRequired(),
    sendEmailAlertWhenPublished: stringType(),
    deviceFingerPrint: stringType(),
    photos: arrayType(customType('UGCSubmissionPhoto')
      .shape({
        url: stringType(),
        caption: stringType()
      }))
  }).mutation().shape(BVResponse)
};
