import React, { useContext } from 'react';
import { bool, number } from 'prop-types';
import { Search } from '../../Filter/index';
import { AskAQuestion } from '../../AskAQuestion/AskAQuestion.component';
import QAContext from '../../../context/QAContext';

export const Header = ({ showTitleWithTotalQns, seoData }) => {
  let { channel, TotalResults, seoPageNumber } = useContext(QAContext);

  if (seoPageNumber && seoData) TotalResults = seoData;

  const showTitleWithTotalResults = () => {
    return (
      <div className="qa-result__count">
        <span className="count--bold">{TotalResults}</span>
        <span>{TotalResults > 1 ? 'Questions' : 'Question'}</span>
      </div>
    );
  };

  return (
    <div className="qa-result__header">
      {showTitleWithTotalQns && showTitleWithTotalResults()}
      <div className="qa-result__search">
        <Search nopadding />
      </div>
      {channel !== 'mobile' && (
        <div className="qa-result__ask-button--desktop">
          <AskAQuestion />
        </div>
      )}
    </div>
  );
};

Header.displayName = 'Header';

Header.propTypes = {
  showTitleWithTotalQns: bool,
  seoData: number
};

Header.defaultProps = {
  showTitleWithTotalQns: true,
  seoData: null
};
