/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';
import helpers from '../helpers';
import * as analytics from '../analytics';

class SmoothScroll extends Component {

  componentDidMount() {
    smoothscroll.polyfill();
  }

  smoothScroll = (event) => {
    event.preventDefault();
    const { targetRef } = this.props;

    window.scroll({
      top: helpers.findPos(targetRef),
      behavior: 'smooth'
    });
    const hash = event.target.hash?.substring(1, event.target.hash.length) || targetRef.current.id;
    analytics.track({
      target: helpers
        .getAnalyticsTarget(hash),
      hash
    });
  };

  render() {
    const { children, className, useButton } = this.props;
    return (useButton) ? (
      <button type="button" onClick={this.smoothScroll} className={className}>
        {children}
      </button>
    ) : (
      <div className={className}>
        {React.cloneElement(children, { onClick: this.smoothScroll })}
      </div>
    );
  }
}

SmoothScroll.propTypes = {
  children: PropTypes.node,
  targetRef: PropTypes.shape({ current: PropTypes.any }),
  /** class modifiers. */
  className: PropTypes.string,
  useButton: PropTypes.bool
};
SmoothScroll.defaultProps = {
  children: null,
  targetRef: null,
  className: null,
  useButton: true
};

export default SmoothScroll;
