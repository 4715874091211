import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { BuyboxMessage } from '@thd-olt-component-react/buybox/dist/component/subcomponents/BuyboxMessage';
import { number } from 'prop-types';

export const BundleQuantityMessaging = ({ maxQuantity, quantity }) => {
  const isTwoTileEnabledFS = useConfigService('isTwoTileEnabled');

  const showMaxQuantity = isTwoTileEnabledFS && maxQuantity && quantity >= maxQuantity;

  if (!showMaxQuantity) return null;

  return (
    <BuyboxMessage
      message={`This bundle has a limit of ${maxQuantity} per order`}
      status="warning"
    />
  );
};

BundleQuantityMessaging.propTypes = {
  maxQuantity: number,
  quantity: number.isRequired
};

BundleQuantityMessaging.defaultProps = {
  maxQuantity: 0
};