import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';

/**
 * Props
 *
 * @typedef {Object} Props
 * @property {string} itemId - The unique identifier of the item to be removed.
 * @property {Function} onRemove - The callback function that is executed when the button is clicked.
 * @property {string} [buttonText='Remove'] - The text to display on the button.
 * @property {string} [buttonVariant='secondary'] - The variant of the button, which determines its styling.
 *
 * @param {Props} props The properties passed to the Remove component.
 * @returns {React.ReactElement} A button element that performs a remove action when clicked.
 */

export const RemoveButton = ({
  itemId,
  onRemove,
  buttonText = 'Remove',
  buttonVariant = 'secondary'
}) => (
  <Button
    id={`remove-cta-${itemId}`}
    fullWidth
    variant={buttonVariant}
    data-testid="drawer-remove-cta-button"
    onClick={() => onRemove(itemId)}
  >
    {buttonText}
  </Button>
);

RemoveButton.propTypes = {
  itemId: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string
};

RemoveButton.defaultProps = {
  itemId: undefined,
  buttonText: 'Remove',
  buttonVariant: 'secondary'
};