import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@one-thd/sui-atomic-components';

import { getRows } from '../helpers';

export const AttributeContent = ({ attributeGroupList, oneColumn, isWhiteSpecTitle }) => {
  const rowSize = oneColumn ? 1 : 2;
  const rows = getRows({ attributeGroupList, rowSize });
  const { specTitle } = attributeGroupList;
  const shouldRenderBlankCells = (row) => row && row.length % 2 === 1 && !oneColumn;
  return (
    <Table name={specTitle}>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow
            key={`${specTitle}-row-${index + 1}`}
            className={`sui-grid sui-grid-cols-2 sui-text-left 
            ${oneColumn ? 'lg:sui-grid-cols-2' : 'lg:sui-grid-cols-4'}`}
          >
            {row.map((attr, i) => (
              <React.Fragment key={`${specTitle}-pair-${i}`}>
                <TableCell
                  className={`${!isWhiteSpecTitle && 'sui-bg-subtle'} break-words
                  sui-px-4 sui-py-2 sui-border-b-1 sui-border-primary sui-border-solid sui-flex`}
                  component="th"
                  key={`${specTitle}-${attr.specName}-${i}`}
                >
                  {attr.specName}
                </TableCell>
                <TableCell
                  className="sui-px-4 sui-py-2 sui-border-b-1 sui-border-primary sui-border-solid"
                  key={`${specTitle}-${attr.specValue}-${i}`}
                >
                  {attr.specValue}
                </TableCell>
              </React.Fragment>
            ))}
            {shouldRenderBlankCells(row) && (
              <>
                <TableCell
                  className="sui-hidden"
                  key={`${specTitle}-blank-header-${index}`}
                />
                <TableCell
                  className="sui-hidden"
                  key={`${specTitle}-blank-data-${index}`}
                />
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

AttributeContent.displayName = 'AttributeContent';

AttributeContent.propTypes = {
  attributeGroupList: PropTypes.shape({
    specifications: PropTypes.arrayOf(PropTypes.shape({
      specValue: PropTypes.string,
      specName: PropTypes.string,
    })),
    specTitle: PropTypes.string,
  }),
  oneColumn: PropTypes.bool,
  isWhiteSpecTitle: PropTypes.bool
};

AttributeContent.defaultProps = {
  attributeGroupList: null,
  oneColumn: false,
  isWhiteSpecTitle: false
};
