import { useState, useEffect } from 'react';
import { offLifeCycleEvent, onLifeCycleEvent } from '../utils/product-bundle-utils';

const changeSelectedItem = (itemId) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.productSpecificationsClicked', { itemId });
};

const getDefaultSelectedProduct = (products) => {
  const filteredList = products?.filter((product) => !product?.removed);
  return filteredList?.[0]?.itemId;
};

const useSetSelectedItemId = ({ products }) => {
  const [selectedItemId, setSelectedItemId] = useState(getDefaultSelectedProduct(products));

  useEffect(() => {
    if (!selectedItemId) {
      changeSelectedItem(getDefaultSelectedProduct(products));
      return;
    }

    const removedProduct = products?.find(
      (product) => product?.itemId === selectedItemId && product?.removed
    );
    if (removedProduct) {
      changeSelectedItem(getDefaultSelectedProduct(products));
      return;
    }

    const swapProduct = products?.find((product) => product?.swappedProduct?.itemId === selectedItemId);
    if (swapProduct) {
      changeSelectedItem(swapProduct.itemId);
    }
  }, [products, selectedItemId]);

  useEffect(() => {
    onLifeCycleEvent('product-bundle.productSpecificationsClicked', ({ output: product }) => {
      setSelectedItemId(product?.itemId);
    });
    return () => {
      offLifeCycleEvent('product-bundle.productSpecificationsClicked');
    };
  }, []);

  return {
    selectedItemId,
    changeSelectedItem
  };
};

export default useSetSelectedItemId;
