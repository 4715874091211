import React, { useEffect, useState, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { arrayOf, func, shape, string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Input } from '@thd-olt-component-react/input';
import { Image, Loader } from '@thd-olt-component-react/core-ui';
import axios from 'axios';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { TEN_MB } from '../../../constants';

export const ImageUpload = ({ formInputClasses, images, setImages, from }) => {
  const [uploading, setUploading] = useState(false);
  const [photoUpload, setPhotoUpload] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [thumbs, setThumbs] = useState([]);

  const { hosts } = useContext(ExperienceContext);
  const prodHost = 'https://api.bazaarvoice.com';
  const { bazaarVoice = prodHost } = hosts || {};
  const passkey = bazaarVoice === prodHost
    ? 'b763vhiq4ca8pmty8eiin091r'
    : '3ielvvzcc4fyg8w6egemffp1u';

  const uploadUrl = `${bazaarVoice}/data/uploadphoto.json?apiversion=5.4&Contenttype=${from}&passkey=${passkey}`;

  const removeImage = (imageIndex) => {
    const newImages = [...images];
    newImages.splice(imageIndex, 1);
    setImages(newImages);
  };

  const updateImageCaption = ({ caption, imageIndex }) => {
    const updated = [...images];
    updated[imageIndex].caption = caption;
    setImages(updated);
  };

  const handleFileChange = async (event) => {
    if (event?.target?.files[0]?.size > TEN_MB) {
      setErrorMessage('Image too large, please try an image smaller than 10 MB');
      return;
    }
    setUploading(true);
    const photoFile = new FormData();
    photoFile.append('photo', event.target.files[0]);
    setPhotoUpload(photoFile);
  };

  useEffect(() => {
    if (uploading && photoUpload) {
      const uploadPhoto = async (photo) => {
        const headers = {
          'Content-Type': 'multipart/form-data'
        };
        const defaultErrorText = `Photo upload failed. Please submit the ${from} without a photo.`;
        const res = await axios.post(uploadUrl, photo, { headers });
        const Photo = res?.data?.Photo;
        const FormErrors = res?.data?.FormErrors;
        const errorResponse = FormErrors?.length > 0 ? FormErrors : defaultErrorText;

        if (Photo) {
          const thumbUrl = Photo.Sizes.thumbnail.Url;
          const newThumbs = [...thumbs];
          newThumbs.push(thumbUrl);
          setThumbs(newThumbs);
          const image = {
            caption: '',
            url: Photo.Sizes.normal.Url
          };
          const newImages = [...images];
          newImages.push(image);
          setImages(newImages);
          setErrorMessage('');
          setUploading(false);
        } else if (errorResponse) {
          setErrorMessage(errorResponse);
          setUploading(false);
        }
      };
      uploadPhoto(photoUpload);
    }
  }, [uploading, photoUpload]);
  return (
    <>
      <Row>
        <Col nopadding>
          <div className={`${formInputClasses}__upload-image`}>
            <span className="upload-title">Want to include a photo?</span>
            <label className={`${formInputClasses}__upload-image__file-input`} htmlFor="file-input">
              <span className={`${formInputClasses}__upload-image__file-input__text`}>Upload Photo</span>
              <input id="file-input" type="file" onChange={handleFileChange} />
            </label>
          </div>
        </Col>
      </Row>
      {uploading
        && (
          <Row>
            <Col nopadding>
              <div className={`${formInputClasses}__upload-image__wrapper`}>
                <Loader active small />
                <div className="write-review-content__upload-image__loader">
                  Please wait while we upload your image.
                </div>
              </div>
            </Col>
          </Row>
        )}
      { errorMessage
      && (
        <Row>
          <span id="error-testing" className="write-review-content__upload-image__error">
            {errorMessage}
          </span>
        </Row>
      )}
      {images.length > 0
        && images.map((image, imageIndex) => (
          <Row key={`${formInputClasses}-${imageIndex}`}>
            <Col xs={1} sm={1} md={1} lg={1} fallback={1} nopadding>
              <button type="button" onClick={() => removeImage(imageIndex)}>
                <Image
                  className={`${formInputClasses}__upload-image__remove-btn`}
                  src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
                  alt="Close"
                />
              </button>
            </Col>
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              fallback={1}
              className={`${formInputClasses}__upload-image__col`}
              nopadding
            >
              <Image
                data-testid="uploaded-photo"
                className="thumbnail"
                src={thumbs[imageIndex]}
                alt={image.caption || ''}
                title={image.caption}
              />
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} fallback={10} className="" nopadding>
              <div className={`${formInputClasses}__upload-image__caption`}>
                <span>Caption</span>
                <Input
                  placeholder="What's in this photo?"
                  value={images[imageIndex].caption}
                  onChange={(caption) => updateImageCaption({ caption, imageIndex })}
                  scrollOnFocus
                />
              </div>
            </Col>
          </Row>
        )
        )}
    </>
  );
};

ImageUpload.displayName = 'ImageUpload';

ImageUpload.propTypes = {
  formInputClasses: string,
  from: string,
  images: arrayOf(shape({ url: string, caption: string, thumbnailUrl: string })),
  setImages: func.isRequired,

};

ImageUpload.defaultProps = {
  formInputClasses: null,
  from: null,
  images: [{}]
};
