import React, { useEffect, useContext } from 'react';
import { Col } from '@thd-olt-component-react/core-ui';
import { AccordionBodyPlaceholder } from './AccordionBodyPlaceholder';
import { QAResultContent } from '../QAResultContent/QAResultContent';
import { NoContent } from '../NoContent/NoContent.component';
import { useQAResult } from '../QAResult/useQAResult';
import { useSEO } from '../QAResult/useSEO';
import QAContext from '../../context/QAContext';
import { dataModel } from '../dataModel';
import { Pager } from '../Pager/Pager.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { triggerAnalytics } from '../../Analytics';
import { QAResultHeader } from '../QAResult/QAResultHeader';
import '../QAResult/QAResult.scss';

const AccordionBodyComponent = () => {
  useEffect(() => { triggerAnalytics('accordion-open'); }, []);

  const { TotalResults, Results, searchText, qaLoading } = useContext(QAContext);
  const qaResult = useQAResult();
  const { seoResults, seoTotalResults, seoAnswers } = useSEO(qaResult);

  if (qaLoading) return <AccordionBodyPlaceholder />;

  const noResults = !seoAnswers && (TotalResults === 0 || Results?.length === 0) && !searchText;
  const resultCount = seoResults ? seoTotalResults : TotalResults;

  return (
    noResults
      ? <NoContent />
      : (
        <>
          <QAResultHeader
            seoResults={seoResults}
            seoTotalResults={seoTotalResults}
          />
          <QAResultContent
            seoResults={seoResults}
            seoAnswers={seoAnswers}
            seoTotalResults={seoTotalResults}
          />
          <Col>
            <Pager seoTotalResults={resultCount} />
            <div className="sui-text-center">
              <PagerDisplayText topPager={false} loading={qaLoading} seoTotalResults={resultCount} />
            </div>
          </Col>
        </>
      )
  );
};

AccordionBodyComponent.displayName = 'AccordionBody';

AccordionBodyComponent.propTypes = {};

AccordionBodyComponent.defaultProps = {};

AccordionBodyComponent.dataModel = dataModel;

export { AccordionBodyComponent };
