/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';

import {
  params, string, arrayOf, number, bool, shape, extend
} from '@thd-nucleus/data-sources';
import { Button, Link } from '@one-thd/sui-atomic-components';
import { ProductDetails } from '@thd-olt-component-react/product-details';

import { BundleMiniPrice } from './BundleMiniPrice';
import { useBundleMini } from '../../hooks/useBundleMini';

const BundleMiniContent = ({ product, products, removeBackgroundImage }) => {
  const { brand, image, label, url } = useBundleMini(product, products);

  const backgroundImage = removeBackgroundImage ? '' : 'sui-bg-inactive';
  return (
    <div
      // eslint-disable-next-line max-len
      className="sui-bg-primary sui-mb-2 lg:sui-mt-2 lg:sui-mb-4 sui-w-full sui-border-solid sui-border-1 sui-border-primary sui-p-4 md:sui-p-6 lg:sui-p-8 sui-grid sui-grid-cols-12 sui-gap-4 lg:sui-gap-8"
    >
      <a
        href={url}
        aria-label="bundle-mini-image"
        className={`sui-flex sui-justify-center sui-py-2 sm:py-4 sui-col-span-12 lg:sui-col-span-7 ${backgroundImage}`}
      >
        <img
          className="sui-block sui-mx-4 sui-w-3/4 sm:sui-w-1/2"
          src={image}
          alt={label}
          width="100%"
          height="100%"
        />
      </a>
      <div
        className="lg:sui-h-full lg:sui-flex lg:sui-flex-col lg:sui-justify-center sui-col-span-12 lg:sui-col-span-5"
      >
        {
          brand && (
            <div className="sui-mb-2" data-testid="bundle-mini-brand">
              <Link variant="body-base" href={url} underline="none">{brand}</Link>
            </div>
          )
        }
        <Link variant="h4" href={url} underline="none">
          {label}
        </Link>
        <div className="sui-mb-2 [&_a]:sui-cursor-auto">
          {
            product?.itemId && (
              <ProductDetails itemId={product?.itemId}>
                <ProductDetails.Ratings />
              </ProductDetails>
            )
          }
        </div>
        <div className="sui-mb-3 sui-min-h-20">
          {
            products && products?.length > 0 && <BundleMiniPrice products={products} />
          }
        </div>
        <div className="sui-w-full sm:sui-w-fit">
          <Button
            fullWidth
            variant="secondary"
            href={url}
          >
            View Package
          </Button>
        </div>
      </div>
    </div>
  );
};

BundleMiniContent.displayName = 'BundleMiniContent';

BundleMiniContent.propTypes = {
  product: PropTypes.shape({
    itemId: PropTypes.string,
    identifiers: PropTypes.shape({
      productLabel: PropTypes.string,
      brandName: PropTypes.string
    }),
    media: PropTypes.shape()
  }),
  products: PropTypes.arrayOf(PropTypes.shape({
    media: PropTypes.shape(),
    pricing: PropTypes.shape()
  })),
  removeBackgroundImage: PropTypes.bool
};

BundleMiniContent.defaultProps = {
  product: undefined,
  products: [],
  removeBackgroundImage: false
};

BundleMiniContent.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      identifiers: shape({
        productLabel: string(),
        brandName: string()
      }),
      media: shape({
        images: arrayOf(shape({
          url: string(),
          sizes: arrayOf(string()),
          type: string(),
          subType: string(),
          altText: string()
        }))
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      identifiers: shape({
        itemId: string(),
        productType: string()
      }),
      media: shape({
        images: arrayOf({
          sizes: arrayOf(string()),
          subType: string(),
          type: string(),
          url: string()
        }),
        threeSixty: arrayOf({
          id: string(),
          url: string()
        }),
        video: arrayOf({
          shortDescription: string(),
          title: string(),
          thumbnail: string(),
          type: string(),
          url: string(),
          videoId: string(),
          videoStill: string()
        })
      }),
      pricing: params({ storeId: string() }).shape({
        original: number({ float: true }),
        mapAboveOriginalPrice: bool(),
        promotion: shape({
          type: string(),
          description: shape({
            shortDesc: string(),
            longDesc: string()
          }),
          dollarOff: number({ float: true }),
          percentageOff: number({ float: true })
        }),
        promotionalAdjustments: arrayOf(shape({
          type: string(),
          description: shape({
            shortDesc: string(),
            longDesc: string()
          }),
          dollarOff: number({ float: true }),
          percentageOff: number({ float: true }),
          promoId: string()
        })),
        specialBuy: number({ float: true }),
        value: number({ float: true })
      })
    })
  },
  ProductDetails
);

export { BundleMiniContent };