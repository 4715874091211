import React, { useEffect, useState, useContext } from 'react';
import { Col } from '@thd-olt-component-react/grid';
import { Input } from '@thd-olt-component-react/input';
import QAContext from '../../context/QAContext';
import { triggerAnalytics } from '../../Analytics';
import './Filter.scss';

export const Search = () => {
  const { searchText, dispatch } = useContext(QAContext);
  const [search, setSearch] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch({ action: 'SET_SEARCH_TEXT', value: search });
    const payload = { component: 'search' };
    triggerAnalytics(payload);
  };

  useEffect(() => {
    // Clear search, update input
    setSearch(searchText);
  }, [searchText]);

  return (
    <Col className="q-and-a__search">
      <form onSubmit={onSubmit}>
        <Input
          placeholder="Search Questions & Answers"
          value={search}
          onChange={(currentValue) => setSearch(currentValue)}
        />
        <button type="submit" aria-label="search" className="search-button" />
      </form>
    </Col>
  );
};

Search.displayName = 'QuestionAndAnswerSearch';
