import React, { useContext } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { QueryContext, QueryProvider, extend } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { ProductPodWrapper } from './product-pod/ProductPodWrapper';

const ElligibleItems = ({
  products, anchorItem, openInQuickViewDescription, openDetailsInNewTab, hideQuickViewMask
}) => {
  const { storeId } = useStore();
  const { defaultVariables } = useContext(QueryContext) || {};

  const newDefaultVariables = {
    storeId,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <div className="elligible-item">
      {products?.map((product, index) => (
        <div key={product.itemId}>
          <QueryProvider
            cacheKey="swap-item-product-pod-mini"
            defaultVariables={newDefaultVariables}
            dataSource={product.dataSource}
          >
            <ProductPodWrapper
              product={product}
              anchorItem={anchorItem}
              swapItemPosition={index + 1}
              openInQuickViewDescription={openInQuickViewDescription}
              openDetailsInNewTab={openDetailsInNewTab}
              hideQuickViewMask={hideQuickViewMask}
            />
          </QueryProvider>
        </div>
      ))}
    </div>
  );
};

ElligibleItems.propTypes = {
  products: arrayOf(shape({})).isRequired,
  anchorItem: string,
  openInQuickViewDescription: bool,
  openDetailsInNewTab: bool,
  hideQuickViewMask: bool
};

ElligibleItems.defaultProps = {
  anchorItem: null,
  openInQuickViewDescription: false,
  openDetailsInNewTab: false,
  hideQuickViewMask: false
};

ElligibleItems.displayName = 'ElligibleItems';

ElligibleItems.dataModel = ProductPodWrapper.dataModel;

export { ElligibleItems };
