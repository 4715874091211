import { useDataModel } from '@thd-nucleus/data-sources';

export const useSubmitAnswer = (formData) => {
  const [submitAnswerForm, response] = useDataModel('submitAnswer', {
    variables: formData,
    lazy: true
  });

  return [
    submitAnswerForm,
    response
  ];
};
