import React, { useContext, useState, lazy, Suspense } from 'react';
import { bool as boolType, shape as shapeType, number as numberType } from 'prop-types';
import classNames from 'classnames/bind';
import { Col, Image, Row } from '@thd-olt-component-react/core-ui';
import {
  QueryProvider,
  params,
  string,
  number,
  bool,
  shape,
  extend,
  arrayOf
} from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { ProductImage, ProductPod, ProductRating } from '@thd-olt-component-react/product-pod';
import { SwapContext } from '../../SwapContext';
import { SelectionButton } from '../selection-button/SelectionButton';
import styles from './ProductPodWrapper.module.scss';

const QuickViewLazy = lazy(() => import(
  /* webpackChunkName: "quick-view" */
  '@thd-olt-component-react/quick-view'
).then((mod) => {
  const { QuickView } = mod;
  return {
    default: (props) => {
      return (
        <QueryProvider
          dataSource="catalog"
          cacheKey="swap-item-quick-item"
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <QuickView {...props} />
        </QueryProvider>
      );
    }
  };
}));

export const ProductPodWrapper = ({
  hideKPF,
  product,
  swapItemPosition,
  openInQuickViewDescription,
  openDetailsInNewTab,
  hideQuickViewMask
}) => {

  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [mountQuickView, setMountQuickView] = useState(false);
  const [isRatingsOpen, setIsRatingsOpen] = useState(false);
  const storeId = useStoreId();
  const { onClose } = useContext(SwapContext);

  const handleOnOpenQuickVIew = (event) => {
    event.preventDefault();
    setIsQuickViewOpen(true);
  };

  const handleClickRating = () => {
    setIsRatingsOpen(true);
    setIsQuickViewOpen(true);
  };

  const handleFromClose = (fromClose) => {
    setIsQuickViewOpen(false);
    setIsRatingsOpen(false);
    if (fromClose) {
      onClose();
    }
  };

  const selectionButton = () => (
    <SelectionButton selectedProduct={product} />
  );

  // This is invoked on mouse enter on product pod
  const loadQuickView = (event) => {
    event.preventDefault();
    // The value will be set only
    // if the mountQuickView is false
    if (!mountQuickView) {
      setMountQuickView(true);
    }
  };

  const showBundleDiscount = Boolean(product.pricing?.promotion?.promoId);

  return (
    <div className={classNames(styles['product-pod-wrapper'])}>
      <div onMouseEnter={loadQuickView} data-testid="swap-item-product-pod">
        <ProductPod
          itemId={product.itemId}
          storeId={storeId}
          analyticsData={{ parent: 'swap-item', position: swapItemPosition }}
          render={(pod) => (
            <>
              <Row>
                <Col fallback="3">
                  { openInQuickViewDescription ? (
                    <ProductImage
                      itemId={pod.itemId}
                      onClick={handleOnOpenQuickVIew}
                    />
                  ) : <ProductImage itemId={pod.itemId} /> }
                </Col>
                <Col fallback="8">
                  {
                    openInQuickViewDescription ? (
                      <ProductHeader
                        brand="above"
                        itemId={pod.itemId}
                        brandTitleMaxLine={4}
                        disableShopThisCollection
                        onClick={handleOnOpenQuickVIew}
                      />
                    )
                      : (
                        <ProductHeader
                          brand="above"
                          itemId={pod.itemId}
                          brandTitleMaxLine={4}
                          disableShopThisCollection
                          data-testid="product-header"
                        />
                      )

                  }
                  { openInQuickViewDescription ? (
                    <ProductRating
                      itemId={pod.itemId}
                      openDetailsInNewTab={!openDetailsInNewTab}
                      data-testid="product-rating"
                      onClick={handleClickRating}
                    />
                  ) : (
                    <div className="sui-pb-4">
                      <ProductRating
                        itemId={pod.itemId}
                        data-testid="product-rating"
                      />
                    </div>
                  )}

                  <div className={classNames(styles['product-pod-wrapper__price-wrapper'])}>
                    <Price
                      itemId={pod.itemId}
                      large={false}
                      storeId={storeId}
                      displayEachUom={false}
                    />
                  </div>
                  {!hideKPF && (
                    <div
                      className={classNames(styles['product-pod-wrapper__kpf-wrapper'])}
                      data-testid="product-pod-wrapper__kpf-wrapper"
                    >
                      <KeyProductFeatures
                        hideImage
                        storeId={storeId}
                        itemId={pod.itemId}
                        maxfeatures={3}
                        simple
                        oneColumnGrid
                      />
                    </div>
                  )}
                </Col>
                {!openInQuickViewDescription && (
                  <Col fallback="1">
                    <Image
                      alt="open quickview"
                      asset="caret-orange.svg"
                      className={classNames(styles['product-pod-wrapper__caret'])}
                      onClick={handleOnOpenQuickVIew}
                      role="button"
                      title="open quick view"
                    />
                  </Col>
                )}
              </Row>
              <div className="selection-button">
                <Col fallback="3">
                  &nbsp;
                </Col>
                <Col fallback="9" className="mini-example__cta-button">
                  {selectionButton()}
                </Col>
              </div>
              { typeof window !== 'undefined' && mountQuickView && (
                <Suspense fallback={<div />}>
                  <QuickViewLazy
                    isOpen={isQuickViewOpen}
                    onClose={handleFromClose}
                    fetch={mountQuickView}
                    itemId={product.itemId}
                    showBackNavLink
                    footerElement={selectionButton()}
                    isRatingsOpen={openDetailsInNewTab && isRatingsOpen}
                    openDetailsInNewTab={openDetailsInNewTab}
                    hideMask={hideQuickViewMask}
                  />
                </Suspense>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};

ProductPodWrapper.propTypes = {
  hideKPF: boolType,
  product: shapeType({}).isRequired,
  swapItemPosition: numberType,
  openInQuickViewDescription: boolType,
  openDetailsInNewTab: boolType,
  hideQuickViewMask: boolType
};

ProductPodWrapper.defaultProps = {
  hideKPF: false,
  swapItemPosition: null,
  openInQuickViewDescription: false,
  openDetailsInNewTab: false,
  hideQuickViewMask: false
};

ProductPodWrapper.displayName = 'ProductPodWrapper';

ProductPodWrapper.dataModel = extend({
  dataSources: string(),
  itemId: string(),
  identifiers: shape({
    itemId: string(),
    canonicalUrl: string(),
    productLabel: string(),
    brandName: string(),
    modelNumber: string(),
    productType: string(),
  }),
  info: shape({
    label: string(),
    productGroup: string(),
  }),
  media: shape({
    images: arrayOf({
      subType: string(),
      url: string(),
      type: string(),
      sizes: arrayOf(string())
    }),
    image: shape({
      url: string()
    }).client(),
  }),
  reviews: shape({
    ratingsReviews: shape({
      averageRating: number(),
      totalReviews: number()
    })
  }),
  pricing: params({ storeId: string() }).shape({
    value: number(),
    mapAboveOriginalPrice: bool(),
    promotionalAdjustments: shape({
      type: string(),
      description: shape({
        shortDesc: string(),
        longDesc: string()
      }),
      dollarOff: number({ float: true }),
      percentageOff: number({ float: true }),
      promoId: string(),
    }),
    mapDetail: shape({
      mapPolicy: string(),
      percentageOff: number({ float: true }),
      dollarOff: number({ float: true })
    })
  })
},
KeyProductFeatures,
Price,
ProductHeader,
ProductImage,
ProductPod,
ProductRating
);
