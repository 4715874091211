/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List } from '@one-thd/sui-atomic-components';

function Links(props) {
  const { children, stickyNavRef } = props;
  const [activeLink, setActiveLink] = useState('');

  const SCROLL_BUFFER_Y = 25;

  useEffect(() => {
    const handleScroll = () => {
      const refs = React.Children.map(children, (el) => {
        return el && {
          rect: el.props?.targetRef?.current?.getBoundingClientRect(),
          id: el.props?.targetRef?.current?.id
        };
      });

      const firstElement = refs.filter((ref) => {
        if (ref.rect === null || ref.rect === undefined) {
          return false;
        }

        const navHeight = stickyNavRef?.current?.getBoundingClientRect().height;

        const topIsOnScreen = ref.rect.top > navHeight + SCROLL_BUFFER_Y
          && ref.rect.top < window.innerHeight;
        const bottomIsOnScreen = ref.rect.bottom > navHeight + SCROLL_BUFFER_Y
          && ref.rect.bottom <= window.innerHeight + navHeight;

        if (topIsOnScreen || bottomIsOnScreen) {
          return true;
        }

        return ref.rect.top < navHeight + SCROLL_BUFFER_Y
            && ref.rect.bottom > window.innerHeight;

      })
        .sort((first, second) => Math.abs(first.rect.top) - Math.abs(second.rect.top))[0];

      if (activeLink !== firstElement?.id) {
        setActiveLink(firstElement?.id || '');
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeLink, children, stickyNavRef]);

  const markActiveElement = (child) => {
    if (!child) return child;

    const isActive = child?.props?.targetRef?.current?.id === activeLink;
    const ACTIVE_CLASSES = 'sui-font-bold sui-border-accent sui-border-solid sui-border-b-2 sui-text-primary';
    const className = isActive ? ACTIVE_CLASSES : 'sui-text-subtle hover:sui-text-primary';

    return React.cloneElement(child, { className });
  };

  return (
    <List className="sui-flex sui-gap-10 lg:sui-gap-12 sui-mt-3">
      {React.Children.map(children, markActiveElement)}
    </List>
  );
}

Links.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  stickyNavRef: PropTypes.shape({ current: PropTypes.any })
};
Links.defaultProps = {
  children: null,
  stickyNavRef: null
};

export default Links;