export default {
  ref: null,
  set stickNavRef(ref) {
    this.ref = ref;
  },
  setStickyNavRef(ref) {
    this.stickNavRef = ref;
  },
  get stickyNavRef() {
    return this.ref;
  },
  getStickyNavRef() {
    return this.ref;
  },
  findPos(targetRef) {
    const stickyNavHeight = this.stickyNavRef?.current?.offsetHeight;
    if (targetRef.current && window) {
      let nodeHeight = targetRef?.current?.getBoundingClientRect().top + window.scrollY;
      return nodeHeight - stickyNavHeight;
    }
    return 0;
  },
  getAnalyticsTarget: (hash) => {
    switch (hash) {
    case 'product-section-overview':
      return 'Product Overview';
    case 'product-section-key-feat':
      return 'Specifications';
    case 'product-section-qa':
      return 'Questions & Answers';
    case 'product-section-rr':
      return 'Customer Reviews';
    default:
      return null;
    }
  }
};
