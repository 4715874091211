import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Typography, Link, Card } from '@one-thd/sui-atomic-components';

const WhatWeOfferItem = ({
  description,
  titleLine1,
  titleLine2,
  icon,
  linkUrl,
  linkText
}) => {

  const [realativeHost, setRelativeHost] = useState('');

  useEffect(() => {
    const currentProtocol = 'https:';
    const currentHost = window.location.host;
    const completeUrl = `${currentProtocol}//${currentHost}${linkUrl}`;
    setRelativeHost(completeUrl);
  }, []);

  return (
    <div
      className="sui-flex"
    >
      <Card
        PaperProps={{
          className: 'sui-flex sui-flex-grow'
        }}
        CardContentProps={{
          grow: true
        }}
      >
        <div className="sui-flex sui-flex-col sui-grow sm:sui-min-w-full">
          <div className="sui-flex sui-flex-row sui-mb-4">
            <div className="sui-my-auto">
              {icon}
            </div>
            <div className="sui-ml-4">
              <Typography variant="h4">{titleLine1}</Typography>
              <Typography variant="h4">{titleLine2}</Typography>
            </div>
          </div>
          <div className="sui-flex sui-flex-col sui-grow sui-justify-between">
            <div className="sui-mb-4">
              {description}
            </div>
            <Link href={realativeHost} target="_blank">
              {linkText}
            </Link>
          </div>
        </div>

      </Card>

    </div>
  );
};

WhatWeOfferItem.propTypes = {
  description: Proptypes.string.isRequired,
  titleLine1: Proptypes.string.isRequired,
  titleLine2: Proptypes.string.isRequired,
  icon: Proptypes.node.isRequired,
  linkUrl: Proptypes.string.isRequired,
  linkText: Proptypes.string.isRequired,
};

export default WhatWeOfferItem;