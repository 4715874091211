import { useContext, useState, useEffect, useRef } from 'react';

import { useVariationIds } from '@thd-olt-component-react/personalization-utils';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useHelmet, LinkTag } from '@thd-nucleus/thd-helmet';

import Helper from '../component/Helper';

import { IMAGE_TYPES } from '../constants';

// Restrictions:
// TNT is limited to area-rugs segment and is implemented on half of the SKUs. So all area-rugs must have a placeholder
// AA does not show placeholder or produce non-primary image as the DE result

// Upcoming Phases of this hook:
// 1. Include AA Testing - current phase, add multi-campaign FS which includes AA test campaigns
// 2. Pick between AA and TNT when both tests are complete and the winner between the two has been decided -
//    next phase (likely), If TNT wins, keep the code as-is and maintain hard-coded TNT campaigns.
//    If AA wins, remove the TNT code.
// 3. Migrate to EdgeWorker - final phase, the decision from DE will be available server and client-side.
//    DE Call can be removed, loading placeholder can be removed. Metadata handling should be fine to stay
//    unless we decide to move this sort of logic to the appropriate spot(s?) in the metadata component

export const useDecisionEngine = ({ media }) => {
  const { segment } = useContext(ExperienceContext);

  const campaignIdTNT = useConfigService('media-gallery.de-main-image'); // T&T adds this campaignId
  const enableDeFeature = useConfigService('fs:enableDeFeature'); // main killswitch for DE feature

  // Expected output is an object with key value pairs where the key is a segment and the campaign Id is the value
  const segmentCampaignIdsString = useConfigService('fs:de-main-image-campaign-ids');
  let segmentCampaignIds = {};
  if (typeof segmentCampaignIdsString === 'string') {
    try {
      segmentCampaignIds = JSON.parse(segmentCampaignIdsString);
    } catch (e) {
      segmentCampaignIds = {};
      console.error('Error parsing segmentCampaignIdsString', e);
    }
  }

  // list of hard-coded TNT campaign(s) segment
  segmentCampaignIds['area-rugs'] = campaignIdTNT;

  const segmentIsPartOfDETest = Object.keys(segmentCampaignIds).includes(segment);

  const [imageType, setImageType] = useState('');
  const [loading, setLoading] = useState(segmentIsPartOfDETest && enableDeFeature);

  const timeoutRef = useRef();

  const threeHrExpiration = new Date(new Date().setHours(new Date().getHours() + 3)).getTime() - Date.now();

  const variationIdResponse = useVariationIds({
    campaignIds: segmentCampaignIds[segment]
      ? [{
        campaignId: segmentCampaignIds[segment]
      }]
      : [],
    localStorageOpts: {
      expiration: threeHrExpiration
    },
    isReady: segmentIsPartOfDETest && enableDeFeature
  });

  const productMetaDataHandler = () => {
    if (!(segmentIsPartOfDETest && enableDeFeature)) {
      return [];
    }

    const { image: primaryImage, images = [] } = media || {};
    const primaryImageUrl = primaryImage?.url?.replace(/_\d+./gm, '_600.');
    const tags = [];

    const filteredImages = images?.filter((image) => {
      return IMAGE_TYPES.find((type) => image?.type === type);
    }) || [];

    filteredImages.forEach(({ url, type }) => {
      const imgUrl = url?.replace(/<SIZE>/ig, '600');

      // Skip primary image, as this is already loaded in the head within ProductMetadata.js
      if (imgUrl !== primaryImageUrl) {
        tags.push(
          new LinkTag({
            rel: 'preload',
            href: imgUrl,
            id: `preLoadImg ${type}`,
            as: 'image',
            media: '(min-width: 600px)',
            fetchpriority: 'high'
          }),
        );
      }
    });

    tags.push(
      new LinkTag({
        rel: 'preload',
        href: 'https://assets.thdstatic.com/images/v1/gray-image-placeholder.jpg',
        id: 'placeholderImg',
        as: 'image',
        fetchpriority: 'high'
      }),
    );
    return tags;
  };

  useHelmet('decisionEngine.mediaGallery', {}, productMetaDataHandler, [segment]);

  const decision = variationIdResponse?.variationIds?.[0]?.decision;

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 10000);

    window.LIFE_CYCLE_EVENT_BUS.on('personalization-utils.timeout', () => {
      setLoading(false);
    });

    window.LIFE_CYCLE_EVENT_BUS.on('personalization-utils.deError', () => {
      setLoading(false);
    });

    return () => {
      clearTimeout(timeoutRef.current);
      window.LIFE_CYCLE_EVENT_BUS.off('personalization-utils.timeout');
      window.LIFE_CYCLE_EVENT_BUS.off('personalization-utils.deError');
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      clearTimeout(timeoutRef.current);
    }
  }, [loading]);

  useEffect(() => {
    const imageTypeFromMap = Helper.armToImageMapping({ decision });
    if (variationIdResponse?.variationIds) {
      setImageType(imageTypeFromMap);
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('media-gallery-rendered', () => {
        setLoading(false);
      });
    }
  }, [variationIdResponse?.variationIds]);

  return {
    imageType,
    loading,
    isNonTNTDETest: segmentIsPartOfDETest && enableDeFeature && segmentCampaignIds[segment] !== campaignIdTNT
  };
};