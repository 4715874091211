import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { TextField } from '@one-thd/sui-atomic-components';
import { validateDisplayName } from '../helper/QAModalFormHelper';

export const DisplayName = ({ displayName, validationError, setDisplayName }) => {
  const [errorTextForDisplayName, setErrorTextForDisplayName] = useState(null);
  const [status, setStatus] = useState(null);

  const handleChange = (event) => {
    const name = event.target.value;
    const { isValidDisplayName, displayNameMessage } = validateDisplayName(name);
    if (isValidDisplayName) {
      setErrorTextForDisplayName(displayNameMessage);
      setStatus('success');
    } else {
      setErrorTextForDisplayName(displayNameMessage);
      setStatus('error');
    }
    setDisplayName(name);
  };

  const helpMessage = 'Please do not use your full name, spaces or symbols.';

  return (
    <div className="sui-mb-6">
      <TextField
        data-testid="displayNameTestId"
        fullWidth
        helpMessage={helpMessage}
        InputProps={{ inputProps: { maxLength: '25' } }}
        label="Display Name"
        onChange={handleChange}
        required
        status={validationError ? 'error' : status}
        statusMessage={validationError || errorTextForDisplayName}
        value={displayName}
      />
    </div>
  );
};

DisplayName.displayName = 'DisplayName';

DisplayName.propTypes = {
  displayName: string,
  setDisplayName: func,
  validationError: string
};

DisplayName.defaultProps = {
  displayName: '',
  setDisplayName: null,
  validationError: ''
};
