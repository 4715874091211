import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
/* eslint-disable */

const Guidelines = ({ from }) => {
  const FROM = from;

  return (
    <div>
      <Typography variant="body-base" weight="bold">For product {FROM}s ("{FROM.charAt(0).toUpperCase() + FROM.slice(1)}s"), please:</Typography>
      <ul className="sui-list-disc sui-list-outside sui-ml-6 sui-my-2">
        <li>Keep your {FROM} focused on the product.</li>
        <li>
          Avoid writing about customer service; contact us instead if you have issues requiring
          immediate attention.
        </li>
        <li>Refrain from mentioning competitors or the specific price you paid for the product.</li>
        <li>Do not include any personal information about yourself or others, such as social security numbers, credit or debit card numbers, payment card data, financial account numbers, account passwords, or government issued identification information.
        </li>
        <li>Avoid promoting or sharing opinions or beliefs about political issues, or religious affiliations.
        </li>
      </ul>
    </div>
  );
};

Guidelines.propTypes = {
  from: string,
};

Guidelines.defaultProps = {
  from: '',
};

export { Guidelines };
