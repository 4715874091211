import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { string, bool } from 'prop-types';
import {
  QueryProvider,
  QueryContext,
  params,
  shape,
  extend,
  useDataModel,
  arrayOf,
  bool as boolType,
  string as stringType,
  number as numberType,
} from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { Rating, Image } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';
import { SwapContext } from '../SwapContext';
import { ProductPodWrapper } from '../elligible-items/product-pod/ProductPodWrapper';
import styles from './CurrentItem.module.scss';

const CurrentItem = ({ itemId, currentItemSelectable }) => {
  const { storeId } = useStore();
  const { setCurrentItem } = useContext(SwapContext);
  const { defaultVariables } = useContext(QueryContext) || {};

  const { data, loading } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    ssr: false,
  });

  useEffect(() => {
    if (!loading && data) {
      setCurrentItem(data?.product);
    }
  }, [data, loading]);

  if (!data) {
    return null;
  }

  const newDefaultVariables = {
    storeId,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  const imageUrl = data?.product?.media?.image?.url;
  const { brandName, productLabel, modelNumber } = data?.product?.identifiers || {};
  const { averageRating, totalReviews } = data?.product?.reviews?.ratingsReviews || {};

  const imageClass = classNames(styles['current-item__details-img']);
  const itemDetailsClass = classNames(styles['current-item__details']);
  const ratingsClass = classNames(styles['current-item__details-ratings']);
  const infoClass = classNames(styles['current-item__details-info']);
  const headerMessage = currentItemSelectable ? 'Most Recent Selection' : 'Current Selection';

  return (
    <div className="current-item" data-testid="current-item">
      <h2 className="u__bold">{headerMessage}</h2>

      {currentItemSelectable ? (
        <QueryProvider
          cacheKey="current-item-product-pod-mini"
          defaultVariables={newDefaultVariables}
          dataSource={data.product.dataSource}
        >
          <ProductPodWrapper
            hideKPF
            product={data.product}
          />
        </QueryProvider>
      ) : (
        <div className={itemDetailsClass}>
          <Image
            src={imageUrl}
            alt="Current Selection"
            title="Current Selection"
            className={imageClass}
            width="1"
            height="1"
          />
          <div className={infoClass}>
            <span className="u__bold">{brandName}</span>&nbsp;{productLabel}
            <div className={ratingsClass}>
              <Rating value={parseFloat(averageRating)} />
              <div>({totalReviews}) | Model # {modelNumber}</div>
            </div>
            <Price
              itemId={itemId}
              storeId={storeId}
              large={false}
              disableRangePricing
              hideBadge
              hideBulkPrice
              hideConditionalPromoDescription
              hideLimitPerOrder
              hidePromotions
              hideSavingsText
              hideRebates
              showPreferredPricingBadge
              showPreferredPricingBadgeToolTip
            />
          </div>
        </div>
      )}
    </div>
  );
};

CurrentItem.propTypes = {
  itemId: string.isRequired,
  currentItemSelectable: bool
};

CurrentItem.defaultProps = {
  currentItemSelectable: false
};

CurrentItem.displayName = 'CurrentItem';

CurrentItem.dataModel = extend({
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSource: stringType(),
    identifiers: shape({
      brandName: stringType(),
      productLabel: stringType(),
      modelNumber: stringType(),
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: numberType(),
        totalReviews: numberType(),
      }),
    }),
    media: shape({
      image: shape({
        url: stringType(),
      }).client(),
      images: arrayOf({
        subType: stringType(),
        url: stringType(),
        type: stringType(),
        sizes: arrayOf(stringType())
      })
    }),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType({ float: true }),
      mapAboveOriginalPrice: boolType(),
      promotion: shape({
        promoId: stringType(),
        dates: shape({
          start: stringType(),
          end: stringType()
        }),
        promotionTag: stringType(),
      })
    }),
  }),
}, Price, ProductPodWrapper.dataModel.product);

export { CurrentItem };
