/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable max-len */
import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  extend,
  params,
  string,
  shape,
  bool,
  QueryContext,
  QueryProvider,
} from '@thd-nucleus/data-sources';
import { node, string as stringType } from 'prop-types';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { CustomerContext } from '@thd-olt-functional/customer-information';
import { useParams, useLocation } from '@thd-olt-component-react/router';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Discontinued } from '@thd-olt-component-react/discontinued';
import { CalcLink } from '@thd-olt-component-react/moving-calculator';
import { Hydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { ProductInfoBar } from '@thd-olt-component-react/product-info-bar';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { DisclaimerMessage } from '@thd-olt-component-react/disclaimer-message';
import { TextAnExpert } from '@thd-olt-component-react/text-an-expert';
import {
  BundleProductList,
  BundleSummary,
  BundleMediaGallery,
  BundleBuybox,
  BundleSalientPoints,
  BundleConsumerCardMessaging,
  BundlePrice,
  BundlePageAnalytics,
  BundleStickyNav,
  BundleAccordions,
  BundleProductOverview,
  BundleWhatWeOffer
} from '@thd-olt-component-react/product-bundle';
import { DynamicComponent, AppContext, PIPRedirector } from '@thd-nucleus/app-render';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { RecentlyViewedWrapper } from '@thd-olt-component-react/thd-recs-containers';
import {
  Sharebelt,
  ShareBeltPrint,
  ShareBeltShareButton,
} from '@thd-olt-component-react/thd-sharebelt';
import { Metadata, BundleInformationMetadata } from '@thd-olt-component-react/metadata';

import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HYDRATION_STANDARD_SCROLL_BUFFER, STICKY_HEADER_HEIGHT } from '../../constants';

import { BundleAccordionsLoad } from './dynamic-imports';

const ZoneCard = ({ children, className }) => (
  <div
    className={`sui-bg-primary sui-rounded-base sui-w-full sui-mb-2 sui-max-w-[100vw] sui-overflow-hidden ${className}`}
  >
    {children}
  </div>
);

ZoneCard.propTypes = {
  children: node.isRequired,
  className: stringType,
};

ZoneCard.defaultProps = {
  className: '',
};

const ChildMap = ({ children }) => {
  return (
    <div className="sui-w-full sui-flex sui-flex-wrap">
      <div className="sui-p-0 sui-w-full">{children}</div>
    </div>
  );
};

ChildMap.propTypes = {
  children: node.isRequired,
};

const Row = ({ children }) => <div className="sui-w-full sui-flex sui-flex-wrap">{children}</div>;

Row.propTypes = {
  children: node.isRequired,
};

export const Bundle = () => {
  const theme = useTheme(Bundle);
  const { hideShareBeltShareButton } = theme.props;
  declareContexts([QueryContext, ExperienceContext, CustomerContext]);
  const productId = useParams()?.itemId;
  const qctx = useContext(QueryContext);
  const [itemId, setItemId] = useState(productId);
  const storeId = useStoreId();
  const hideRatingsIfHDPPSku = true;
  const ctx = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);
  const { channel, isConsumerApp } = ctx;
  const { pathname } = useLocation();
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;
  const isMobile = channel === 'mobile';
  const isHDPPSku = false;
  const productOverviewRef = useRef(null);
  const specificationsRef = useRef(null);
  const questionsRef = useRef(null);
  const ratingsRef = useRef(null);
  const stickyNavRef = useRef(null);

  const accordionsRefs = {
    productOverviewRef,
    specificationsRef,
    questionsRef,
    ratingsRef,
    stickyNavRef,
  };

  useEffect(() => {
    if (productId !== itemId) {
      setItemId(productId);
    }
  }, [productId]);

  return (
    <div className="sui-bg-primary">
      <ErrorBoundary name="redirector">
        <PIPRedirector disablePEPRedirect disableCanonicalRedirect disableCMMMCRedirect />
      </ErrorBoundary>
      <ErrorBoundary name="bip-content">
        <BundlePageAnalytics bundleId={itemId} storeId={storeId} listenToProductChanges={false} />
        <ErrorBoundary name="bip-metadata">
          <Metadata>
            <BundleInformationMetadata itemId={itemId} />
          </Metadata>
        </ErrorBoundary>
        <div className="sui-w-full sui-flex sui-flex-wrap sui-max-w-[1440px] sui-my-[0] sui-mx-[auto]">
          {channel === 'desktop' && (
            <div className="sui-w-full sui-flex sui-flex-wrap">
              {/* sticky bar */}
              <ErrorBoundary name="sticknav">
                <BundleStickyNav
                  bundleId={itemId}
                  storeId={storeId}
                  stickyNavRef={stickyNavRef}
                  accordionsRefs={accordionsRefs}
                  isHDPPSku={isHDPPSku}
                />
              </ErrorBoundary>
            </div>
          )}
          {/* Breadcrumbs & ProductInfoBar  */}
          <div className="sui-py-3 sui-w-full sui-flex sui-flex-wrap sui-grid sui-grid-cols-12">
            <ErrorBoundary name="breadcrumb-productInfoBar-grp">
              <div className="sui-overflow-x-auto sui-overflow-y-hidden  sui-p-2 sui-py-0 sui-grid sui-col-span-12 lg:sui-col-span-7">
                <ErrorBoundary name="breadcrumbs">
                  <Breadcrumbs itemId={itemId} useStencilDesign />
                </ErrorBoundary>
              </div>
              {channel === 'desktop' && (
                <div className="sui-overflow-visible sui-py-0 sui-hidden xl:sui-grid sui-col-span-5">
                  <ErrorBoundary name="product-info-bar">
                    <div className="sui-flex sui-justify-end">
                      <ProductInfoBar itemId={itemId} />
                    </div>
                  </ErrorBoundary>
                </div>
              )}
            </ErrorBoundary>
          </div>
          {/* zone A */}
          <div className="sui-w-full sui-flex sui-flex-wrap">
            <ErrorBoundary name="zone-a">
              <div className={`sui-w-full ${channel === 'desktop' && 'sui-p-0'}`}>
                <div className="sui-w-auto sui-grid sui-grid-cols-12">
                  <div className="sui-w-full sui-grid sui-col-span-12">
                    <Discontinued itemId={itemId} />
                  </div>
                  <div className="lg:sui-sticky lg:sui-top-0 lg:sui-h-max lg:sui-z-10 sui-grid sui-col-span-12 lg:sui-col-span-7 lg:sui-px-3 sui-mb-4">
                    <ZoneCard className="sui-p-2 sui-overflow-visible">
                      <div className="sui-w-full sui-p-0 sui-relative">
                        <BundleProductOverview
                          bundleId={itemId}
                          storeId={storeId}
                          hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
                          listenToProductChanges={false}
                        />
                      </div>
                      <ErrorBoundary name="media-gallery-desktop">
                        <div className="sui-w-full sui-p-2">
                          <BundleMediaGallery bundleId={itemId} storeId={storeId} showLoader />
                        </div>
                      </ErrorBoundary>
                      <div className="sui-w-full sui-p-2">
                        <ErrorBoundary name="sharebelt">
                          <Sharebelt horizontal itemId={itemId}>
                            { !hideShareBeltShareButton && <ShareBeltShareButton /> }
                            <ShareBeltPrint />
                          </Sharebelt>
                        </ErrorBoundary>
                      </div>
                    </ZoneCard>
                  </div>
                  <div className="sui-grid sui-col-span-12 lg:sui-col-span-5 sui-overflow-visible sui-px-3 sui-mb-4">
                    <ZoneCard className="sui-overflow-visible">
                      {/* price */}
                      <ErrorBoundary name="price">
                        <div className="sui-py-2 empty:sui-hidden">
                          <BundlePrice
                            bundleId={itemId}
                            storeId={storeId}
                            channel={channel}
                            displayEachUom={false}
                            itemId={itemId}
                            large
                            disableRangePricing
                            clsRemediation={{
                              placeholders: true,
                              preservePlaceholders: true,
                            }}
                          />
                        </div>
                      </ErrorBoundary>
                      {/* Disclaimer Message */}
                      <ErrorBoundary name="disclaimer-message">
                        <div className="sui-py-4 empty:sui-hidden">
                          <DisclaimerMessage itemId={itemId} />
                        </div>
                      </ErrorBoundary>
                      {/* Consumer Card Messaging */}
                      <ErrorBoundary name="consumer-card-messaging">
                        <div className="sui-py-4 empty:sui-hidden">
                          <BundleConsumerCardMessaging
                            bundleId={itemId}
                            storeId={storeId}
                            listenToProductChanges={false}
                            clsRemediation={{
                              placeholders: true,
                              preservePlaceholders: true,
                            }}
                          />
                        </div>
                      </ErrorBoundary>
                      {/* Salient Points */}
                      <ErrorBoundary name="salient">
                        <div className="sui-py-4 empty:sui-hidden">
                          <BundleSalientPoints
                            bundleId={itemId}
                            storeId={storeId}
                            max={3}
                            productOverviewSection="product-section-overview"
                            stickyHeaderHeight={STICKY_HEADER_HEIGHT}
                            listenToProductChanges={false}
                          />
                        </div>
                      </ErrorBoundary>
                      {/* list */}

                      <ErrorBoundary name="itemized-products">
                        <div className="sui-py-4 empty:sui-hidden">
                          <BundleProductList
                            accordionProps={{
                              accordionName: '#product-section-key-feat',
                              scrollStickyHeaderHeight: STICKY_HEADER_HEIGHT,
                            }}
                            showSummary={false}
                            bundleId={itemId}
                            storeId={storeId}
                          />
                        </div>
                        {/* summary */}
                        <ErrorBoundary name="bundle-discount">
                          <div className="empty:sui-hidden">
                            <BundleSummary bundleId={itemId} storeId={storeId} />
                          </div>
                        </ErrorBoundary>
                      </ErrorBoundary>
                      {/* buybox */}
                      <ErrorBoundary name="bundle-buybox">
                        <BundleBuybox bundleId={itemId} storeId={storeId} />
                      </ErrorBoundary>
                    </ZoneCard>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </div>

          {/* Accordions  */}
          <ErrorBoundary name="collapsible-sections">
            <DynamicComponent pageType="pip">
              <Hydrator
                id="collapsible-sections-accordion"
                className="sui-w-full sui-flex sui-flex-wrap"
                delay={1500}
                scrollBuffer={50}
                waitFor={BundleAccordionsLoad}
                placeholder={(
                  <div className={`sui-w-full ${isMobile && 'sui-px-4'}`}>
                    <Skeleton disablePadding SkeletonContentProps={{ grow: true }}>
                      <SkeletonBlock height={14} aspect="wide" id="product-section-overview" />
                      <SkeletonBlock height={14} aspect="wide" id="product-section-key-feat" />
                      <SkeletonBlock height={14} aspect="wide" id="product-section-qa" />
                      <SkeletonBlock height={14} aspect="wide" id="product-section-rr" />
                    </Skeleton>
                  </div>
                )}
              >
                <ZoneCard className="sui-m-2 lg:sui-mx-0 lg:sui-mt-2 lg:sui-mb-4">
                  <BundleAccordions
                    bundleId={itemId}
                    storeId={storeId}
                    accordionsRefs={accordionsRefs}
                    isHDPPSku={isHDPPSku}
                    thdSeoBotDetected={thdSeoBotDetected}
                    qctx={qctx}
                  />
                </ZoneCard>
              </Hydrator>
            </DynamicComponent>
          </ErrorBoundary>
          <Hydrator id="bundle-what-we-offer" className="sui-w-full sui-flex sui-flex-wrap">
            <BundleWhatWeOffer qctx={qctx} bundleId={itemId} />
          </Hydrator>
          {!isConsumerApp && channel === 'mobile' && (
            <div className="sui-w-full sui-flex sui-flex-wrap">
              <ErrorBoundary name="text-an-expert">
                <Hydrator id="text-an-expert-section" className="sui-w-full sui-flex sui-flex-wrap">
                  <TextAnExpert />
                </Hydrator>
              </ErrorBoundary>
            </div>
          )}
          <div className="sui-w-full sui-flex sui-flex-wrap sui-mt-4">
            <QueryProvider cacheKey="rv-pip-recs" persist defaultProps={defaultVariables.current}>
              <RecentlyViewedWrapper
                schemaName="rv_gm_pip_rr"
                errorBoundary
                hydrator={{
                  className: 'sui-w-full sui-flex sui-flex-wrap',
                  tag: ZoneCard,
                  id: 'rv_gm_pip_rr',
                  delay: 2000,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER,
                }}
              />
            </QueryProvider>
          </div>
          <EmtLinks
            path={pathname}
            layoutGroup={{
              tag: Row,
            }}
            dynamic={{
              pageType: 'pip',
            }}
            hydrator={{
              className: 'sui-w-full sui-p-2 sui-mt-4',
            }}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

Bundle.displayName = 'BundlePage';

Bundle.themeProps = {
  hideShareBeltShareButton: bool
};

Bundle.defaultThemeProps = {
  hideShareBeltShareButton: false
};

Bundle.dataModel = extend(
  {
    clientOnlyProduct: params({ itemId: string().isRequired }).shape({
      itemId: string(),
      dataSources: string(),
      info: shape({
        gccExperienceOmsId: string(),
        recommendationFlags: shape({
          visualNavigation: bool(),
          pipCollections: bool(),
          packages: bool(),
          ACC: bool(),
          collections: bool(),
          frequentlyBoughtTogether: bool(),
          bundles: bool(),
        }),
        dotComColorEligible: bool(),
      }),
      identifiers: shape({
        roomVOEnabled: bool(),
        productType: string(),
        skuClassification: string(),
      }),
      availabilityType: shape({
        discontinued: bool(),
        type: string(),
      }),
      seoDescription: string(),
    }),
  },
  EmtLinks,
  Breadcrumbs,
  Sharebelt,
  Discontinued,
  ProductInfoBar,
  ProductDetails,
  Price,
  DisclaimerMessage,
  CalcLink,
  BundlePrice,
  BundleProductList,
  BundleBuybox,
  BundleSummary,
  BundleMediaGallery,
  BundleSalientPoints,
  BundlePageAnalytics,
  RecentlyViewedWrapper,
  BundleConsumerCardMessaging,
  BundleStickyNav,
  BundleAccordions,
  BundleProductOverview,
  BundleWhatWeOffer,
  PIPRedirector
);
