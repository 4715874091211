import React from 'react';
import PropTypes, { shape as shapeProp } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {number} productsCount - The number of products in the bundle
 */

/**
 * Returns a formated Title.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const ProductListTitle = ({ productsCount, product }) => {
  const productCountString = ` (${productsCount})`;
  const title = product?.features?.productListTitle?.concat(productCountString);

  return (
    <Typography variant="body-lg" weight="bold" data-testid="product-list-header">
      {title}
    </Typography>
  );
};

ProductListTitle.propTypes = {
  productsCount: PropTypes.number.isRequired,
  product: shapeProp()
};

ProductListTitle.defaultProps = {
  product: null
};

ProductListTitle.displayName = 'ProductListTitle';

export { ProductListTitle };
