import React, { useContext } from 'react';
import { arrayOf, shape } from 'prop-types';
import {
  Accordion, AccordionBody, AccordionTitle, Col, Placeholder
} from '@thd-olt-component-react/core-ui';
import { QuestionContainer } from '../QAResult/component/QuestionContainer';
import { AnswerContainer } from '../QAResult/component/AnswerContainer';
import QAContext from '../../context/QAContext';
import { useQAResult } from '../QAResult/useQAResult';
import { triggerAnalytics } from '../../Analytics';
import { dataModel } from '../dataModel';
import '../QAResult/QAResult.scss';

const QAResultContent = ({ seoAnswers, seoResults }) => {
  const { channel, qaLoading, seoPageNumber, Results } = useContext(QAContext);
  const qaResult = useQAResult();

  const isMobile = channel === 'mobile';
  const isSeoPage = !!(seoPageNumber && qaResult);
  const results = seoResults || Results || [];

  if (qaLoading) {
    return (
      <>
        <Placeholder
          type="rect"
          height="125px"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
      </>
    );
  }

  const analyticsReadQuestion = () => {
    const payload = { component: 'read question' };
    triggerAnalytics(payload);
  };

  return (
    <Col
      className={isMobile ? 'qa-result__questions qa-result__accordion' : 'qa-result__questions'}
      aria-label="questions"
      nopadding={!isMobile}
    >
      {results.map((result) => (
        <Accordion
          icon={{ size: 'sm', iconPosition: isMobile ? 'left' : 'right' }}
          onChange={analyticsReadQuestion}
          key={result.Id}
          expanded={isSeoPage}
        >
          <AccordionTitle>
            <QuestionContainer result={result} />
          </AccordionTitle>
          <AccordionBody>
            <AnswerContainer result={result} seoData={seoAnswers} />
          </AccordionBody>
        </Accordion>
      ))}
    </Col>
  );
};

QAResultContent.displayName = 'QAResultContent';

QAResultContent.propTypes = {
  seoAnswers: arrayOf(shape({})),
  seoResults: arrayOf(shape({})),
};

QAResultContent.defaultProps = {
  seoAnswers: null,
  seoResults: null,
};

QAResultContent.dataModel = dataModel;

export { QAResultContent };
