import React from 'react';
import {
  string as propTypeString,
  number as propTypeNumber,
  bool as propTypeBool,
  shape as shapeProp,
  arrayOf as propTypeArrayOf
} from 'prop-types';
import {
  params, string, arrayOf, shape, extend
} from '@thd-nucleus/data-sources';
import { SponsoredCarousel } from '@thd-olt-component-react/sponsored-content';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {string} browserId - Browser Id
 * @property {string} userId - User Id
 * @property {Object} pageContext - Page context.
 * @property {{pageType: string}} dynamic - Set page type
 * @property {object} hydrator - Lazy load component property
 * @property {object} product - The main product
 * @property {boolean} showSponsoredCarousel - Show sponsored carousel
 */

/**
 * Returns a component, Salient poinst bullets
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */
const BundleSponsoredCarousel = ({
  browserId, userId, pageContext, dynamic, hydrator, product, showSponsoredCarousel
}) => (
  <div data-component="BundleSponsoredCarousel" data-testid="bundle-sponsored-carousel">
    <SponsoredCarousel
      breadCrumbs={product?.taxonomy?.breadCrumbs || []}
      browserId={browserId}
      userId={userId}
      pageContext={pageContext}
      showSponsoredCarousel={showSponsoredCarousel}
      dynamic={dynamic}
      hydrator={hydrator}
    />
  </div>
);

BundleSponsoredCarousel.propTypes = {
  product: shapeProp({}),
  browserId: propTypeString,
  userId: propTypeString,
  showSponsoredCarousel: propTypeBool,
  pageContext: shapeProp({
    data: shapeProp({
      searchModel: shapeProp({
        appliedDimensions: propTypeArrayOf(shapeProp())
      }),
      itemId: propTypeString,
      itemIds: propTypeArrayOf(propTypeString)
    }),
    isPip: propTypeBool,
    page: propTypeString,
    schema: propTypeString
  }),
  dynamic: shapeProp({
    pageType: propTypeString
  }),
  hydrator: shapeProp({
    className: propTypeString,
    scrollBuffer: propTypeNumber
  })
};

BundleSponsoredCarousel.defaultProps = {
  product: null,
  userId: null,
  hydrator: null,
  browserId: '',
  dynamic: {},
  pageContext: {},
  showSponsoredCarousel: false
};

BundleSponsoredCarousel.displayName = 'BundleSponsoredCarousel';

BundleSponsoredCarousel.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      taxonomy: shape({
        breadCrumbs: arrayOf({
          browseUrl: string(),
          creativeIconUrl: string(),
          deselectUrl: string(),
          dimensionName: string(),
          label: string(),
          refinementKey: string(),
          url: string()
        })
      })
    })
  },
  SponsoredCarousel
);

export { BundleSponsoredCarousel };