import React from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';

export const AccordionTitlePlaceholder = () => (
  <div data-component="QuestionsAndAnswersAccordionTitlePlaceholder">
    <Placeholder
      type="rect"
      height="50px"
      width="100%"
    />
  </div>
);

AccordionTitlePlaceholder.displayName = 'QuestionsAndAnswersAccordionTitlePlaceholder';