import React from 'react';
import PropTypes from 'prop-types';
import { SwapButton } from './SwapButton';
import { RemoveWithDrawer } from './RemoveWithDrawer';

export const SwapOptions = (props) => {
  const {
    product,
    setSelectedItemId,
    swapProducts,
    index,
    triggerAnalytics,
    setIsDrawerOpen,
    isDrawerOpen,
    isMobile
  } = props;

  const { allowSwap, allowRemove } = product?.features ?? {};

  if (!(allowSwap || allowRemove)) return null;

  return (
    <div
      className="sui-grid sui-grid-cols-2 sui-gap-4"
      data-testid="swap-and-remove-container"
    >
      {allowSwap && (
        <SwapButton
          itemId={product?.itemId}
          swapProducts={swapProducts}
          setSelectedItemId={setSelectedItemId}
          itemIndex={`${index + 1}`}
          isMobile={isMobile}
        />
      )}
      {allowRemove && (
        <RemoveWithDrawer
          itemId={product?.itemId}
          closeDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
          setSelectedItemId={setSelectedItemId}
          triggerAnalytics={() => triggerAnalytics(index)}
        />
      )}
    </div>
  );
};

SwapOptions.propTypes = {
  product: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    features: PropTypes.shape({
      allowSwap: PropTypes.bool.isRequired,
      allowRemove: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  setSelectedItemId: PropTypes.func.isRequired,
  swapProducts: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  triggerAnalytics: PropTypes.func.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool
};

SwapOptions.defaultProps = {
  isMobile: false
};
