import React, { useContext } from 'react';
import { arrayOf, shape, number } from 'prop-types';
import { Button, Col } from '@thd-olt-component-react/core-ui';
import { Search, Sort } from '../Filter';
import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { Header } from './component/Header';
import QAContext from '../../context/QAContext';
import { useQAResult } from './useQAResult';
import { dataModel } from '../dataModel';

export const QAResultHeader = ({ seoResults, seoTotalResults }) => {
  const {
    channel, qaLoading, dispatch, TotalResults, searchText, Results
  } = useContext(QAContext);
  const isMobile = channel === 'mobile';
  const qaResult = useQAResult();

  const results = seoResults || Results;
  const resultsCount = seoResults ? seoTotalResults : TotalResults;

  const handleClearSearchPill = () => {
    dispatch({ action: 'SET_SEARCH_TEXT', value: '' });
  };

  return (
    isMobile
      ? (
        <>
          <div className="qa-result__row">
            <h3 className="qa-result__accordion-header">{resultsCount}</h3>
            <span>&nbsp;{resultsCount > 1 ? 'questions' : 'question'}</span>
          </div>
          <div className="qa-result__row">
            <Search />
            {searchText && (
              <div className="mobile-search-pill-container">
                <Button className="search-pill" onClick={handleClearSearchPill} filter>
                  {results?.length ? searchText : 'Clear search term'}
                </Button>
              </div>
            )}
          </div>
          <div className="qa-result__row">
            <AskAQuestion />
          </div>
          <div className="grid qa-result__row qa-result__sort">
            <PagerDisplayText topPager loading={qaLoading} />
            <Sort />
          </div>
        </>
      )
      : (
        <>
          <Col>
            <Header showTitleWithTotalQns seoData={seoTotalResults} />
          </Col>
          {!qaResult && (
            <div className="qa-result__title">
              <PagerDisplayText topPager loading={qaLoading} />
              {searchText && (
                <Button className="search-pill" onClick={handleClearSearchPill} filter>
                  {results?.length ? searchText : 'Clear search term'}
                </Button>
              )}
              <Sort />
            </div>
          )}
        </>
      )
  );
};

QAResultHeader.displayName = 'QAResultHeader';

QAResultHeader.propTypes = {
  seoResults: arrayOf(shape({})),
  seoTotalResults: number,
};

QAResultHeader.defaultProps = {
  seoResults: null,
  seoTotalResults: null,
};

QAResultHeader.dataModel = dataModel;
