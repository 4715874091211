import React, { useContext, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { string, bool, func, shape } from 'prop-types';
import classNames from 'classnames/bind';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { useDataModel, QueryContext } from '@thd-nucleus/data-sources';
import { DrawerHeader, DrawerBody, Drawer } from '@one-thd/sui-atomic-components';
import { Alert } from '@thd-olt-component-react/core-ui';
import { dataModel } from './dataModel';
import { ElligibleItems } from '../elligible-items/ElligibleItems';
import { CurrentItem } from '../current-item/CurrentItem';
import styles from './SwapDrawer.module.scss';
import { SwapContextProvider } from '../SwapContext';

const SwapDrawer = ({
  itemId,
  promoId,
  openSwapDrawer,
  onClose,
  fetch,
  onSwap,
  hideCurrentSelectedItem,
  currentItemSelectable,
  selectionButtonContent,
  openInQuickViewDescription,
  openDetailsInNewTab,
  hideQuickViewMask,
  itemIndex,
  drawerRootProps,
  openCurrentItemInQuickView
}) => {
  const { channel } = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext) || {};
  const { storeId } = useStore();
  const [currentItem, setCurrentItem] = useState({});
  const { data, loading, error } = useDataModel('packageSwapRecs', {
    variables: {
      itemId,
      storeId,
      promoId
    },
    ssr: false,
    skip: !itemId || !fetch
  });

  useEffect(() => {
    if (!loading && openSwapDrawer) {
      if (data?.packageSwapRecs?.products) {
        const output = {
          position: itemIndex
        };
        window.LIFE_CYCLE_EVENT_BUS.trigger('swap-item.success', output);

      } else {
        const message = error?.message || 'n/a';
        const code = error?.graphQLErrors?.[0]?.extensions?.id || 500;
        window.LIFE_CYCLE_EVENT_BUS.trigger('swap-item.error', { message, code });
      }
    }
  }, [data, loading, openSwapDrawer, error]);

  if (!data) {
    return null;
  }

  const isMobile = channel !== 'desktop';

  const swapItemClass = classNames(styles['swap-item']);
  const swapItemBodyClass = classNames(styles['swap-item__body']);

  const swappableProducts = data?.packageSwapRecs?.products?.filter((product) => product.itemId !== itemId);

  return createPortal(
    <div className={swapItemClass} data-component="SwapDrawer">
      <Drawer
        open={openSwapDrawer && !loading && fetch}
        onClose={onClose}
        DrawerRootProps={drawerRootProps}
      >
        <SwapContextProvider value={{
          onSwap,
          currentItem,
          selectionButtonContent,
          setCurrentItem,
          openSwapDrawer,
          onClose
        }}
        >
          {!hideCurrentSelectedItem
            && (
              <DrawerHeader onClose={onClose}>
                <CurrentItem
                  itemId={itemId}
                  storeId={storeId}
                  currentItemSelectable={currentItemSelectable}
                  openCurrentItemInQuickView={openCurrentItemInQuickView}
                  openDetailsInNewTab={openDetailsInNewTab}
                  hideQuickViewMask={hideQuickViewMask}
                />
              </DrawerHeader>
            )}
          <DrawerBody name="swap-products">
            <div>
              <section className={swapItemBodyClass}>
                {data?.packageSwapRecs?.products?.length > 0
                  ? (
                    <>
                      <h3 className="u__bold">Select {hideCurrentSelectedItem ? 'an item' : 'a different item'}:</h3>
                      <ElligibleItems
                        products={swappableProducts}
                        anchorItem={itemId}
                        openInQuickViewDescription={openInQuickViewDescription}
                        openDetailsInNewTab={openDetailsInNewTab}
                        hideQuickViewMask={hideQuickViewMask}
                      />
                    </>
                  )
                  : (
                    <Alert
                      type="danger"
                      message="Sorry! No eligible items available to swap."
                      inline
                    />
                  )}
              </section>
            </div>
          </DrawerBody>
        </SwapContextProvider>
      </Drawer>
    </div>,
    document.body
  );
};

SwapDrawer.propTypes = {
  itemId: string.isRequired,
  promoId: string,
  openSwapDrawer: bool.isRequired,
  onClose: func.isRequired,
  fetch: bool.isRequired,
  onSwap: func.isRequired,
  hideCurrentSelectedItem: bool.isRequired,
  selectionButtonContent: string,
  currentItemSelectable: bool,
  openInQuickViewDescription: bool,
  openDetailsInNewTab: bool,
  hideQuickViewMask: bool,
  itemIndex: string,
  drawerRootProps: shape({}),
  openCurrentItemInQuickView: bool
};

SwapDrawer.defaultProps = {
  promoId: null,
  selectionButtonContent: null,
  currentItemSelectable: false,
  openInQuickViewDescription: false,
  openDetailsInNewTab: false,
  hideQuickViewMask: false,
  itemIndex: 'n/a',
  drawerRootProps: {},
  openCurrentItemInQuickView: false
};

SwapDrawer.displayName = 'SwapDrawer';

SwapDrawer.dataModel = dataModel;

export { SwapDrawer };
