import React, { cloneElement, isValidElement, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { SkeletonBlock } from '@one-thd/sui-atomic-components';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { dataModel } from '../dataModel';
import { SpecificationGroupContent } from './SpecificationGroupContent';
import { track } from '../specifications-analytics';

export const SpecificationsAccordionBodyComponent = (props) => {
  const {
    itemId,
    children,
    oneColumn,
    returnableStatus,
    isWhiteSpecTitle
  } = props;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const { data, loading } = useDataModel('product', { variables: { itemId } });

  useEffect(() => { track('accordion-open'); }, []);

  if (loading) {
    return (
      <div
        className="sui-w-full"
        data-component="SpecificationsAccordionBodyPlaceholder"
      >
        <SkeletonBlock aspect="wide" height={52} width="100%" />
      </div>
    );
  }

  if (!data || !data?.product?.specificationGroup) {
    return null;
  }

  const { specificationGroup } = data.product;

  return (
    <div
      className="sui-flex sui-flex-wrap sui-w-full sui-box-border
      sui-pointer-events-auto sui-gap-6"
      id="specifications"
      data-component="SpecificationsAccordionBody"
    >
      {children && cloneElement(children, {
        itemId,
        maxfeatures: 8,
        minfeatures: isMobile ? 4 : 3,
        channel,
        displaySimilarLinks: true
      })}
      <SpecificationGroupContent
        specificationGroup={specificationGroup}
        oneColumn={oneColumn}
        returnableStatus={returnableStatus}
        isWhiteSpecTitle={isWhiteSpecTitle}
      />
    </div>
  );
};

SpecificationsAccordionBodyComponent.displayName = 'SpecificationsAccordionBodyComponent';

SpecificationsAccordionBodyComponent.dataModel = dataModel;

const propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  returnableStatus: PropTypes.bool,
  /** Can take an (optional) single child component of type KeyProductFeatures */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error;
    if (prop && (!isValidElement(prop) || prop.type.name !== 'KeyProductFeatures')) {
      error = new Error(`'${componentName}' only accepts a single child of type 'KeyProductFeatures'`);
    }
    return error;
  },
  oneColumn: PropTypes.bool,
  isWhiteSpecTitle: PropTypes.bool
};

const defaultProps = {
  children: null,
  oneColumn: false,
  returnableStatus: false,
  isWhiteSpecTitle: false
};

SpecificationsAccordionBodyComponent.propTypes = propTypes;
SpecificationsAccordionBodyComponent.defaultProps = defaultProps;

export const SpecificationsAccordionBody = withErrorBoundary(SpecificationsAccordionBodyComponent);

SpecificationsAccordionBody.dataModel = dataModel;
SpecificationsAccordionBody.propTypes = propTypes;
SpecificationsAccordionBody.defaultProps = defaultProps;
