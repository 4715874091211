export const COMBO_EXPERIENCE_FEATURES = {
  questionsAndAnswers: true,
  ratingsAndReview: true,
  starRatingProductOverview: true,
  starRatingStickyNav: true,
  questionStickyNav: true,
  whatWeOffer: false,
  consumerCard: false,
  quantity: true,
  paypalCheckout: true,
  addToCart: true,
  salientPoint: true,
  starRatingStickyNavTitle: true,
  swapToaster: false,
  dynamicImageGrid: false,
  productListTitle: 'Items in this bundle',
  priceUnitOfMeasure: 'bundle',
  bundleSummaryTitle: 'Bundle Summary',
  deliveryInfo: false,
  deliveryExpress: true,
  deliveryAndTax: false,
  summaryRegularPriceType: 'items',
  calculateDeliveryFee: false,
  showBundleSavings: false,
  showUnavailableMessage: true
};

export const LAUNDRY_EXPERIENCE_FEATURES = {
  questionsAndAnswers: false,
  ratingsAndReview: false,
  starRatingProductOverview: false,
  starRatingStickyNav: false,
  questionStickyNav: false,
  whatWeOffer: true,
  consumerCard: true,
  quantity: false,
  paypalCheckout: false,
  addToCart: false,
  salientPoint: false,
  starRatingStickyNavTitle: false,
  swapToaster: true,
  dynamicImageGrid: true,
  productListTitle: 'Appliances in this package',
  priceUnitOfMeasure: 'bundle',
  bundleSummaryTitle: 'Bundle Summary',
  deliveryInfo: true,
  deliveryExpress: false,
  deliveryAndTax: false,
  summaryRegularPriceType: 'appliances',
  calculateDeliveryFee: false,
  showBundleSavings: false,
  showUnavailableMessage: false
};

export const KITCHEN_EXPERIENCE_FEATURES = {
  questionsAndAnswers: false,
  ratingsAndReview: false,
  starRatingProductOverview: false,
  starRatingStickyNav: false,
  questionStickyNav: false,
  whatWeOffer: true,
  consumerCard: true,
  quantity: false,
  paypalCheckout: false,
  addToCart: false,
  salientPoint: false,
  starRatingStickyNavTitle: false,
  swapToaster: true,
  dynamicImageGrid: true,
  productListTitle: 'Appliances in this package',
  priceUnitOfMeasure: 'package',
  bundleSummaryTitle: 'Package Summary',
  deliveryInfo: true,
  deliveryExpress: false,
  deliveryAndTax: true,
  summaryRegularPriceType: 'appliances',
  calculateDeliveryFee: true,
  showBundleSavings: true,
  showUnavailableMessage: false
};

export const KITCHEN_PRODUCTS_FEATURE = {
  quickView: true,
  productLabel: false,
  productListFuelType: true,
  productListModelNumber: true,
  productListRating: true,
  productListViewSpecificationLink: false,
  directDeliveryCartMethod: true
};

export const LAUNDRY_PRODUCTS_FEATURE = {
  quickView: true,
  productLabel: false,
  productListModelNumber: true,
  productListRating: true,
  productListViewSpecificationLink: false
};

export const COMBO_PRODUCTS_FEATURE = {
  quickView: false,
  productLabel: true,
  productListModelNumber: false,
  productListRating: false,
  productListViewSpecificationLink: true
};