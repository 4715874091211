import { DEFAULT_PREQUAL_URL, DEFAULT_APPLYNOW_URL, B2B_DEFAULT_APPLYNOW_URL } from './constants';

const getScriptInHead = (head, url) => {
  const scripts = head.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i += 1) {
    if (scripts[i].src === url) return scripts[i];
  }
  return false;
};

export const loadEstimatorScript = (fileVersion, onLoadFunction, isMobile) => {
  function paymentEstimatorExists() {
    return Boolean(window.THD?.PaymentEstimator);
  }

  function createScript(fileUrl, head) {
    const script = document.createElement('script');
    script.setAttribute('src', fileUrl);
    script.onload = () => {
      onLoadFunction(isMobile);
    };
    head.appendChild(script);
  }

  function updateExistingScript(head, fileUrl) {
    const script = getScriptInHead(head, fileUrl);
    if (paymentEstimatorExists()) {
      onLoadFunction(isMobile);
    } else {
      let previousOnLoad = script.onload;
      script.onload = () => {
        previousOnLoad();
        onLoadFunction(isMobile);
      };
    }
  }

  if (paymentEstimatorExists()) {
    onLoadFunction(isMobile);
  } else {
    const location = window ? window.location.host.toLowerCase() : '';
    const isLlcEnvironment = !location.includes('www.homedepot.com');
    const fileUrl = isLlcEnvironment
      ? `https://assets-qa.homedepot-static.com/paymentestimator/${fileVersion}/payment-estimator.js`
      : `https://assets.homedepot-static.com/paymentestimator/${fileVersion}/payment-estimator.js`;
    const head = document.querySelector('head');
    if (getScriptInHead(head, fileUrl)) {
      updateExistingScript(head, fileUrl);
    } else {
      createScript(fileUrl, head);
    }
  }
};

const introOfferDetails = (estimator, isMobile, pageName, isB2B) => {
  const { defaultApplynowLinks, defaultB2BApplynowLinks } = estimator || {};
  let links = null;
  if (isB2B) {
    links = defaultB2BApplynowLinks ? defaultB2BApplynowLinks(pageName) : null;
  } else {
    links = defaultApplynowLinks ? defaultApplynowLinks(pageName) : null;
  }
  if (!links) {
    return isB2B ? B2B_DEFAULT_APPLYNOW_URL : DEFAULT_APPLYNOW_URL;
  }
  return { applyNowUrl: isMobile ? links.mobileUrl : links.desktopUrl };
};

const prequalOfferDetails = (estimator, isMobile, pageName, invitationNumber) => {
  const { prequalApplyNowLinks } = estimator || {};
  const links = prequalApplyNowLinks ? prequalApplyNowLinks(pageName, invitationNumber) : null;
  if (!links) {
    return { prequalUrl: DEFAULT_PREQUAL_URL };
  }
  return { prequalUrl: isMobile ? links.mobileUrl : links.desktopUrl };
};

export const getIntroOfferLink = (isMobile, pageName = 'pip', isB2B = false) => {
  const estimator = window && window.THD ? (window.THD || {}).PaymentEstimator : null;
  if (!estimator) {
    return false;
  }
  return introOfferDetails(estimator, isMobile, pageName, isB2B).applyNowUrl;
};

export const getPrequalOfferLink = (isMobile, pageName, invitationNumber) => {
  const estimator = window && window.THD ? (window.THD || {}).PaymentEstimator : null;
  if (!estimator) {
    return false;
  }
  return prequalOfferDetails(estimator, isMobile, pageName, invitationNumber).prequalUrl;
};

export const getPaymentEstimatorDetails = (price, isMobile, isAppliance, isHDHome, pageName = 'pip') => {
  const estimator = window && window.THD ? (window.THD || {}).PaymentEstimator : null;
  if (!estimator) {
    return false;
  }
  if (price < 299) {
    return introOfferDetails(estimator, isMobile, pageName);
  }
  const { currentPromotionDates, estimatedPayments, paymentEstimatorApplynowLinks, toolTipText } = estimator;
  const { monthlyPayment, promotionTerm } = estimatedPayments ? estimatedPayments(price, isAppliance, isHDHome) : {};
  const { promoStartDate, promoEndDate } = currentPromotionDates
    ? currentPromotionDates(isAppliance, isHDHome, price) : {};
  const { mobileUrl, desktopUrl } = paymentEstimatorApplynowLinks
    ? paymentEstimatorApplynowLinks(pageName, isAppliance, isHDHome, price) : {};
  const tooltipText = toolTipText ? toolTipText(isAppliance) : null;
  let details = {};

  if (window.THD?.PaymentEstimator) {
    details = {
      displayPrice: monthlyPayment,
      promotionTerm,
      applyNowUrl: isMobile ? mobileUrl : desktopUrl,
      activeStartDate: promoStartDate,
      activeEndDate: promoEndDate,
      tooltipText
    };
  }
  return details;
};
