import { useState, useEffect } from 'react';
import { getSetUnavailableMessage } from '../utils/product-bundle-utils';

const useIsProductsErrorNotAvailable = ({ error, products }) => {
  const [producstErrorNotAvailable, setProducstErrorNotAvailable] = useState(false);

  useEffect(() => {
    const setUnavaiableMessage = getSetUnavailableMessage({ errorMessage: error?.message, products });

    setProducstErrorNotAvailable(setUnavaiableMessage);
  }, [error, products]);

  return { producstErrorNotAvailable };
};

export default useIsProductsErrorNotAvailable;
