import React from 'react';
import { Col, Row } from '@thd-olt-component-react/grid';
import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import './NoContent.scss';

export const NoContent = () => {
  return (
    <div className="first-question">
      <Row>
        <Col xs="12" className="first-question__button">
          <AskAQuestion />
        </Col>
        <Col xs="12" className="first-question__text">
          <p>Typical questions asked about products:</p>
          <ul className="customQuestions">
            <li>Is the product durable?</li>
            <li>Is the product easy to use?</li>
            <li>What are the dimensions of the product?</li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

NoContent.displayName = 'NoContent';
