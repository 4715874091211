import {
  arrayOf as arrayType,
  params,
  string as stringType,
  bool as booleanType,
  shape as shapeType
} from '@thd-nucleus/data-sources';

const HelpfulFeedbackResponse = {
  Errors: arrayType(stringType()),
  AuthorSubmissionToken: stringType(),
  HasErrors: booleanType(),
  Feedback: shapeType({
    FeedbackHelpfulness: shapeType({
      Vote: stringType(),
      AuthorId: stringType()
    }),
    FeedbackInappropriate: shapeType({
      ReasonText: stringType(),
      AuthorId: stringType()
    })
  })
};

export const dataModel = {
  submitReviewFeedback: params({
    id: stringType().isRequired(),
    feedbacktype: stringType(),
    contenttype: stringType().isRequired(),
    vote: stringType()
  })
    .mutation()
    .shape(HelpfulFeedbackResponse)
};
