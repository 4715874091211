import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { AccordionTitlePlaceholder } from './AccordionTitlePlaceholder';
import { useQAResult } from '../QAResult/useQAResult';
import QAContext from '../../context/QAContext';
import { dataModel } from '../dataModel';
import '../QAResult/QAResult.scss';

const AccordionTitleComponent = ({ noSpacing }) => {
  const { TotalResults, qaLoading } = useContext(QAContext);

  useQAResult();

  if (qaLoading) return <AccordionTitlePlaceholder />;

  return (
    <div
      className={`qa-result__accordion ${noSpacing ? 'qa-result__accordion--no-spacing' : ''}`}
    >
      <Typography variant="h5" component="h3">Questions &amp; Answers</Typography>
      {TotalResults > 0
        && (
          <Typography color="subtle">
            { TotalResults } { TotalResults === 1 ? 'Question' : 'Questions' }
          </Typography>
        )}
    </div>
  );
};

AccordionTitleComponent.displayName = 'QuestionsAnswersAccordionTitle';

AccordionTitleComponent.propTypes = {
  noSpacing: bool
};

AccordionTitleComponent.defaultProps = {
  noSpacing: false
};

AccordionTitleComponent.dataModel = dataModel;

export { AccordionTitleComponent };