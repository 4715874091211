import React, { useEffect, useContext } from 'react';
import { Select } from '@thd-olt-component-react/core-ui';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import './Filter.scss';

export const Sort = () => {
  const { searchText, sortBy, dispatch } = useContext(QAContext);

  const sortOptions = [
    { name: 'Newest Questions', value: 'newest' },
    { name: 'Oldest Questions', value: 'oldest' },
    { name: 'Most Answered', value: 'totalanswercount' },
    { name: 'Most Helpful', value: 'mosthelpfull' },
    { name: 'Featured Questions', value: 'featuredreview' },
    { name: 'Can you answer these questions', value: 'leastanswers' }
  ];

  const onSortChange = (event) => {
    let { value } = event.target;

    dispatch({ action: 'SET_SORT_BY', value });
    dispatch({ action: 'SET_CURRENT_PAGE', value: 1 });

    const selectedIndex = sortOptions.findIndex(
      (index) => index.value === value
    );

    const payload = {
      component: 'sort by',
      element: sortOptions[selectedIndex].name.toLowerCase()
    };

    triggerAnalytics(payload);
  };

  return (
    <div className={`q-and-a__sort${searchText ? ' sort-disabled' : ''}`}>
      <Select selected={sortBy} options={sortOptions} onChange={onSortChange} />
    </div>
  );
};

Sort.displayName = 'QuestionsAndAnswerSort';
