import {
  extend, arrayOf, params, shape, string, number, bool, client
} from '@thd-nucleus/data-sources';
import { Price } from '@thd-olt-component-react/price';
import { ProductPod } from '@thd-olt-component-react/product-pod';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { ProductHeader } from '@thd-olt-component-react/product-details';

import { CurrentItem } from '../current-item/CurrentItem';
import { ProductPodWrapper } from '../elligible-items/product-pod/ProductPodWrapper';

const dataModel = extend({
  packageSwapRecs: params({
    itemId: string().isRequired(),
    storeId: string(),
    promoId: string()
  }).shape({
    products: params().arrayOf(shape(extend({
      itemId: string(),
      availabilityType: shape({
        buyable: bool(),
        discontinued: bool(),
        status: bool(),
        type: string(),
      }),
      dataSource: string(),
      identifiers: shape({
        itemId: string(),
        canonicalUrl: string(),
        productLabel: string(),
        brandName: string(),
        modelNumber: string(),
        productType: string(),
        storeSkuNumber: string()
      }),
      info: shape({
        fiscalYear: string(),
        classNumber: string(),
        isLiveGoodsProduct: bool()
      }),
      badges: shape({
        label: string(),
        message: string(),
        name: string(),
      }),
      details: shape({
        description: string(),
        descriptiveAttributes: shape({
          value: string()
        })
      }),
      reviews: shape({
        ratingsReviews: shape({
          averageRating: number(),
          totalReviews: number()
        })
      }),
      pricing: params({ storeId: string() }).shape({
        promotion: shape({
          dates: shape({
            start: string(),
            end: string()
          }),
          promotionTag: string(),
          promoId: string()
        }),
        mapDetail: shape({
          mapPolicy: string(),
          mapSpecialPriceViolation: bool(),
          mapOriginalPriceViolation: bool(),
          percentageOff: number({ float: true }),
          dollarOff: number({ float: true })
        })
      }),
      fulfillment: client(params({ storeId: string(), zipCode: string() }).shape({
        fulfillmentOptions: arrayOf(shape({
          services: arrayOf(shape({
            type: string(),
            locations: arrayOf(shape({
              isAnchor: bool(),
              inventory: shape({
                quantity: number()
              })
            })),
          }))
        }))
      })),
      media: shape({
        image: shape({
          url: string()
        }).client(),
        images: arrayOf({
          subType: string(),
          url: string(),
          type: string(),
          sizes: arrayOf(string())
        })
      })
    }, Price.dataModel.product, ProductPodWrapper.dataModel.product))),
  }),
}, CurrentItem, ProductPodWrapper.dataModel.product);
delete dataModel.packageSwapRecs.products._query;

export { dataModel };
