import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';

const WhatWeOfferPlaceholder = () => {
  const { channel } = useContext(ExperienceContext);
  const size = channel === 'mobile' ? 36 : 48;

  return (
    <div
      className="sui-flex sui-flex-row sui-flex-wrap"
      data-testid="what-we-offer-placeholder"
    >
      <div className="sm:sui-w-full md:sui-w-1/2 lg:sui-w-1/4">
        <Skeleton disablePadding>
          <SkeletonBlock height={size} aspect="wide" />
        </Skeleton>
      </div>
      <div className="sm:sui-w-full md:sui-w-1/2 lg:sui-w-1/4">
        <Skeleton disablePadding>
          <SkeletonBlock height={size} aspect="wide" />
        </Skeleton>
      </div>
      <div className="sm:sui-w-full md:sui-w-1/2 lg:sui-w-1/4">
        <Skeleton disablePadding>
          <SkeletonBlock height={size} aspect="wide" />
        </Skeleton>
      </div>
      <div className="sm:sui-w-full md:sui-w-1/2 lg:sui-w-1/4">
        <Skeleton disablePadding>
          <SkeletonBlock height={size} aspect="wide" />
        </Skeleton>
      </div>
    </div>
  );
};

export { WhatWeOfferPlaceholder };