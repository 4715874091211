import { useMemo } from 'react';

import { getBundleMedia } from '../utils/product-bundle-utils';

export const useBundleMini = (product, products) => {

  /**
   * solution to get the image for bundle mini is in-progress
   * for now, using the main image of the bundle
   */
  const image = useMemo(() => {
    if (!product?.media) return '';
    const bundleMedia = getBundleMedia(product, products);
    return bundleMedia?.media?.image?.url;
  }, [product, products]);

  const url = product?.identifiers?.canonicalUrl;
  const label = product?.identifiers?.productLabel;
  const brand = product?.identifiers?.brandName;

  return {
    brand,
    image,
    label,
    url
  };
};