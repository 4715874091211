/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  arrayOf as propTypeArrayOf, shape as propTypeShape, string as propTypeString, bool as propTypeBool,
  number as propTypeNumber
} from 'prop-types';
import {
  params, string, arrayOf, number, bool, shape, extend
} from '@thd-nucleus/data-sources';
import { Typography, Alert, Tooltip } from '@one-thd/sui-atomic-components';
import {
  formatPrice, mapPriceExistsInBundle, getMaxQuantityLimit, filterRemovedProducts,
  getBundleQuantity
} from '../utils/product-bundle-utils';
import { MajorApplianceDeliveryAndTaxContent } from './subcomponents/MajorApplianceDeliveryAndTaxContent';
import { AdditionalSavingsContent } from './subcomponents/AdditionalSavingsContent';
import PriceContainer from './subcomponents/PriceContainer';
import { TOOLTIP_VIEW_DETAILS, MIN_ADVERTISED_PRICE_MESSAGE } from '../constants';
import usePriceAdjustment from '../hooks/usePriceAdjustment';
import useDeliveryData from '../hooks/useDeliveryData';
import useBundleSummaryCalculations from '../hooks/useBundleSummaryCalculations';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {Product[]} products Array of Products
 * @property {String} itemId parent itemid
 */

/**
 * Properties of a product
 *
 * @typedef {object} Product
 * @property {object} pricing The pricing of a product
 * @property {object} identifiers The identifiers of a product
 * @property {object} info The info of  a product
 */

/**
 * Returns a component, BundleSummary, showing the total price and
 * discounts applied to the bundle.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleSummary = ({ product, products, itemId, isCustomerIdentified }) => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-summary.ready');
  }, []);

  const {
    bundleSummaryTitle, priceUnitOfMeasure, deliveryAndTax, summaryRegularPriceType, calculateDeliveryFee, showBundleSavings
  } = product?.features ?? {};

  const savingsDetail = 'The amount you save from individual items in this bundle';

  const mapDetail = mapPriceExistsInBundle(products, isCustomerIdentified);

  const bundleQuantity = getBundleQuantity(products);

  const activeProducts = useMemo(() => filterRemovedProducts(products), [products]);

  const deliveryData = useDeliveryData(activeProducts, calculateDeliveryFee);

  const adjustedPricing = usePriceAdjustment(activeProducts);

  const { quantityLimit } = getMaxQuantityLimit(activeProducts);

  const calculations = useBundleSummaryCalculations(
    itemId,
    adjustedPricing,
    mapDetail,
    deliveryData,
    quantityLimit,
    priceUnitOfMeasure);

  const { minAdvertisedPrice, showMapInfo } = mapDetail ?? {};

  const showMapAlert = showMapInfo && minAdvertisedPrice;

  if (!adjustedPricing && !calculations) {
    return null;
  }

  const suiRowClasses = 'sui-flex sui-flex-row sui-justify-between sui-mt-2';
  const dashRowClass = 'sui-flex sui-flex-col sui-items-center';
  const dashClass = 'sui-w-3 sui-bg-primary sui-border-solid sui-border-strongest sui-border-b-1 sui-mr-5 sui-ml-auto sui-h-4';
  const finalPrice = formatPrice(calculations?.finalDiscountedPriceWithDeliveryFee);
  const isSubtotal = true;

  return (
    <div
      className="sui-flex sui-gap-4 sui-flex-col sui-mx-4"
      data-component="BundleSummary"
      data-testid="bundle-summary"
    >
      <Typography variant="body-lg" weight="bold">{bundleSummaryTitle}</Typography>
      <span>
        <div className={suiRowClasses}>
          <Typography variant="body-base">Regular Price ({bundleQuantity} {summaryRegularPriceType})</Typography>
          <PriceContainer minAdvertisedPrice={showMapAlert} dashClass={dashRowClass}>
            <Typography variant="body-base" data-testid="retail-price">
              {formatPrice(calculations?.bundleSummaryPrice)}
            </Typography>
          </PriceContainer>
        </div>
        {calculations?.bundleItemSavings > 0 ? (
          <div className={suiRowClasses}>
            <span className="sui-flex sui-gap-1 sui-items-baseline">
              <span className="sui-flex sui-gap-1 sui-items-center">
                <Typography variant="body-base" color="success">Item Savings</Typography>
              </span>
              {!showMapAlert
                && (
                  <Tooltip offset="normal" title={savingsDetail} data-testid="bundle-savings-tooltip">
                    <Typography
                      variant="body-xs"
                      decoration="underline"
                      data-testid="bundle-view-details"
                    >
                      {TOOLTIP_VIEW_DETAILS}
                    </Typography>
                  </Tooltip>
                )}
            </span>
            <PriceContainer minAdvertisedPrice={showMapAlert} dashClass={dashRowClass}>
              <Typography variant="body-base" color="success" data-testid="product-savings">
                -{formatPrice(calculations?.bundleItemSavings)}
              </Typography>
            </PriceContainer>
          </div>
        ) : null}
        <div className="sui-flex sui-flex-row sui-justify-between sui-mt-2">
          <Typography variant="body-base">Subtotal</Typography>
          <PriceContainer minAdvertisedPrice={showMapAlert} isSubtotal={isSubtotal} dashClass={dashRowClass + ' sui-ml-auto'}>
            <span className="sui-border-solid sui-border-b-1 sui-pb-3 sui-pl-4 sui-w-18">
              <span className="sui-text-right" />
              <Typography variant="body-base" data-testid="subtotal">
                {formatPrice(calculations?.subTotal)}
              </Typography>
            </span>
          </PriceContainer>
        </div>
      </span>

      {calculations?.bundleSavings > 0 ? (
        <AdditionalSavingsContent calculations={calculations} showBundleSavings={showBundleSavings} />
      ) : null}

      {deliveryAndTax ? (
        <MajorApplianceDeliveryAndTaxContent deliveryData={deliveryData} />
      ) : (
        <div className={`${suiRowClasses} sui-items-center`}>
          <Typography variant="body-base">Delivery & Taxes calculated at checkout</Typography>
          <div className={dashRowClass}><span className={dashClass} data-testid="map-price-dash" /></div>
        </div>
      )}
      <div className={`${suiRowClasses} sui-border-solid sui-border-t-1 sui-border-button-inactive sui-py-4`}>
        <Typography variant="body-lg" weight="bold">Total</Typography>

        <PriceContainer minAdvertisedPrice={showMapAlert} dashClass={dashRowClass + ' sui-mt-5'}>
          <span className="sui-text-right">
            {
              finalPrice
                ? <Typography variant="h3" component="p" weight="bold" data-testid="finalDiscountedPrice">{finalPrice}</Typography>
                : <div className={dashRowClass}><span className={dashClass} data-testid="map-price-dash" /></div>
            }
            {calculations?.bundleSummaryPrice > calculations?.finalDiscountedPrice && (
              <>
                <span className="sui-line-through">
                  <Typography variant="body-base" data-testid="retailPrice">
                    {formatPrice(calculations?.bundleSummaryPrice)}
                  </Typography>
                </span>
                <Typography variant="body-base" color="success" data-testid="bundle-total-dollar-off">
                  Save {formatPrice(calculations?.totalDollarOff)} <span>({Math.round(calculations?.totalPercentOff)}%)</span>
                </Typography>
              </>
            )}
          </span>
        </PriceContainer>
      </div>
      {
        showMapAlert && (<Alert status="info">{MIN_ADVERTISED_PRICE_MESSAGE}</Alert>)
      }
    </div>
  );
};

BundleSummary.propTypes = {
  products: propTypeArrayOf(propTypeShape({
    pricing: propTypeShape(),
    bundleSpecificationDetails: propTypeShape({
      components: propTypeArrayOf(propTypeShape({
        quantity: propTypeNumber
      }))
    })
  })).isRequired,
  itemId: propTypeString.isRequired,
  product: propTypeShape({
    features: propTypeShape({
      bundleSummaryTitle: propTypeString.isRequired,
      priceUnitOfMeasure: propTypeString.isRequired
    })
  }).isRequired,
  isCustomerIdentified: propTypeBool
};

BundleSummary.defaultProps = {
  isCustomerIdentified: false
};

BundleSummary.displayName = 'BundleSummary';

BundleSummary.dataModel = extend(
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      pricing: params({ storeId: string(), isBrandPricingPolicyCompliant: bool() }).shape({
        original: number({ float: true }),
        mapAboveOriginalPrice: bool(),
        mapDetail: shape({
          mapPolicy: string(),
          mapOriginalPriceViolation: bool(),
          mapSpecialPriceViolation: bool()
        }),
        promotion: shape({
          type: string(),
          description: shape({
            shortDesc: string(),
            longDesc: string()
          }),
          dollarOff: number({ float: true }),
          percentageOff: number({ float: true })
        }),
        specialBuy: number({ float: true }),
        value: number({ float: true })
      }),
      identifiers: shape({
        brandName: string(),
        storeSkuNumber: string(),
        productLabel: string(),
        omsThdSku: string(),
        modelNumber: string(),
        canonicalUrl: string()
      })
    })
  }
);

export { BundleSummary };
