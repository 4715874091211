/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@one-thd/sui-atomic-components';
import SmoothScroll from './SmoothScroll.component';

const Link = (props) => {
  const { targetRef, children, className } = props;
  return (
    <ListItem
      className={'sui-p-1 hover:sui-bg-subtle ' + (className === null ? '' : className)}
      data-testid="sticky-nav-items"
    >
      <SmoothScroll targetRef={targetRef} className="sui-text-lg sui-leading-normal">
        {children}
      </SmoothScroll>
    </ListItem>
  );
};

Link.propTypes = {
  targetRef: PropTypes.shape({ current: PropTypes.any }),
  children: PropTypes.node,
  /** class modifiers. */
  className: PropTypes.string,
};
Link.defaultProps = {
  className: null,
  targetRef: null,
  children: null
};

export default Link;