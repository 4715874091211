import React, { useContext, useMemo, useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import { Row, Col } from '@thd-olt-component-react/grid';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { formatDate } from '../../../helpers/QAHelper';
import { AnswerAQuestion } from '../../AnswerAQuestion/AnswerAQuestion.component';
import { Voting } from '../../Voting/Voting.component';
import { MediaCarousel } from './MediaCarousel';
import { UserBadges } from './UserBadges';
import QAContext from '../../../context/QAContext';
import { getHighlightedText } from '../helpers/getHighlightedText';
import { UserProfile } from '../../UserProfile/UserProfile';

export const AnswerContainer = ({ result, seoData }) => {
  const {
    UserNickname: resultNickName,
    SubmissionTime,
    QuestionSummary,
    QuestionDetails,
    AnswerIds: answerIds,
    Id: questionId,
    Photos: questionPhotos
  } = result;
  let { Answers, searchText, seoPageNumber } = useContext(QAContext);

  if (seoPageNumber && seoData) Answers = seoData;

  const [showUserOverlay, setShowUserOverlay] = useState(null);

  const handleUserOverlay = (index) => {
    setShowUserOverlay(index);
  };

  const filteredAnswers = useMemo(() => {
    if (Answers?.length) {
      return Answers?.filter((answer) => {
        return answerIds.indexOf(answer?.AnswerId) !== -1;
      });
    }

    return [];
  }, [Answers]);

  return (
    <div className="qa-result__answer">
      <MediaCarousel photos={questionPhotos} uploadedClassName="uploaded-question-photo" />
      <AnswerAQuestion
        question={QuestionDetails || QuestionSummary}
        userName={resultNickName}
        askedDate={formatDate(SubmissionTime)}
        questionId={questionId}
      />
      {filteredAnswers?.length > 0
        && filteredAnswers.map((answer, index) => {
          const {
            Id,
            AnswerText,
            AuthorId,
            LastModificationTime,
            UserNickname,
            BadgesOrder,
            TotalPositiveFeedbackCount,
            Photos
          } = answer?.Answer;

          return (
            <div className="qa-result__answer_container" key={index}>
              <Row>
                <Col xs={6} className="qa-result__answer--left">
                  <p>
                    <span className="qa-result__answer--bold">A:&nbsp; </span>
                    <span className="answer-text">
                      {getHighlightedText(Id, AnswerText, searchText)}
                    </span>
                  </p>
                  <div className="qa-title_info">
                    <span>by</span>
                    <button type="button" onClick={() => handleUserOverlay(index)} className="qa-result__answer_user">
                      {UserNickname}
                    </button>
                    <span className="date-divider">|</span>
                    <span>{formatDate(LastModificationTime)}</span>
                  </div>
                </Col>
                <Col xs={6} className="qa-result__answer--right">
                  <UserBadges badges={BadgesOrder} />
                  <MediaCarousel photos={Photos} uploadedClassName="uploaded-answer-photo" question={QuestionSummary} />
                </Col>
              </Row>
              <Voting totalPositiveFeedbackCount={TotalPositiveFeedbackCount} answerId={Id} />
              <Overlay
                onClose={() => setShowUserOverlay(null)}
                open={showUserOverlay === index}
                positionedCloseButton
                closeButton
                medium
              >
                <UserProfile
                  showUserOverlay={showUserOverlay === index}
                  userNickname={UserNickname}
                  authorId={AuthorId}
                />
              </Overlay>
            </div>
          );
        })}
    </div>
  );
};

AnswerContainer.displayName = 'AnswerContainer';

AnswerContainer.propTypes = {
  result: shape({}),
  seoData: arrayOf(shape({}))
};

AnswerContainer.defaultProps = {
  result: {},
  seoData: null
};
