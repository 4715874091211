import React from 'react';
import { shape, func, string } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { PlaceholderImage } from '../PlaceholderImage/PlaceholderImage';

export const TemplateImage = ({ product, handlePlaceholderClick }) => {

  if (!product) return null;

  const { url, active, itemId, productGroupLabel } = product || {};

  return active
    ? (
      <Image
        src={url}
        stretchy
        width={1}
        height={1}
        alt="product"
        className="template-img"
        data-testid="template-img"
        lazy={false}
      />
    ) : (
      <PlaceholderImage
        itemId={itemId}
        productGroupLabel={productGroupLabel}
        handlePlaceholderClick={handlePlaceholderClick}
      />
    );
};

TemplateImage.propTypes = {
  product: shape({}).isRequired,
  handlePlaceholderClick: func.isRequired
};
