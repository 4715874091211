/* eslint-disable max-len */
import React from 'react';
import { Skeleton } from '@one-thd/sui-atomic-components';
import { CustomSkeletonLine } from './CustomSkeletonLine';

export const SwapProductLoader = () => {

  return (
    <div className="sui-w-full sui-flex" data-testid="skeleton-loader">
      <div className="sui-w-1/4">
        <Skeleton grow disableGutters disablePadding>
          <CustomSkeletonLine height="100px" width="100px" />
        </Skeleton>
      </div>
      <div className="sui-w-3/4">
        <div className="sui-grid sui-grid-cols-1 sui-gap-2">
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" width="75%" />
          </Skeleton>
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" />
          </Skeleton>
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" width="50%" />
          </Skeleton>
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" />
          </Skeleton>
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" width="75%" />
          </Skeleton>
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" />
          </Skeleton>
          <Skeleton grow disableGutters disablePadding>
            <CustomSkeletonLine height="28px" width="50%" />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};