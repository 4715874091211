import React from 'react';
import {
  func, arrayOf, shape, string, bool
} from 'prop-types';
import { TemplateImage } from '../TemplateImage/TemplateImage';
import './four-appliance-template.scss';

export const FourApplianceTemplate = ({ images, handlePlaceholderClick, customStyles }) => {

  if (!images.length) return null;

  return (
    <div
      data-testid="four-appliance-template"
      className={`package-image-grid-container ${customStyles}`}
      data-component="FourApplianceTemplate"
    >
      {
        images.slice(0, 4).map((product) => {
          return (
            <div key={product.itemId} className={product.active && 'package-image-grid-container__product-img'}>
              <TemplateImage product={product} handlePlaceholderClick={handlePlaceholderClick} />
            </div>
          );
        })
      }
    </div>
  );
};

FourApplianceTemplate.propTypes = {
  images: arrayOf(shape({
    url: string.isRequired,
    itemId: string.isRequired,
    active: bool.isRequired
  })).isRequired,
  handlePlaceholderClick: func.isRequired,
  customStyles: string
};

FourApplianceTemplate.defaultProps = {
  customStyles: null
};