import { useDataModel } from '@thd-nucleus/data-sources';

export const useSubmitQuestion = (formData) => {
  const [submitQuestionForm, response] = useDataModel('submitQuestion', {
    variables: formData,
    lazy: true,
  });

  return [submitQuestionForm, response];
};
