import React from 'react';
import PropTypes from 'prop-types';
import { DeliveryExpress, Assemble, Apron } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';

export const DeliveryInfo = ({ deliveryExpress }) => (
  <div
    data-component="DeliveryInfo"
    data-testid="delivery-info"
    className="sui-flex sui-gap-4 sui-w-full sui-pt-4"
  >
    {deliveryExpress && <DeliveryExpress size="large" />}
    <div>
      <Typography
        variant="body-base"
        weight="bold"
        height="tight"
      >
        Options Available with Checkout:
      </Typography>
      <div className="sui-flex sui-flex-col md:sui-flex-row
         md:sui-pl-4 sui-pt-4 md:sui-pt-0 lg:sui-pt-4 lg:sui-pl-0 sui-mt-2"
      >
        <div className="sui-flex sui-pb-4 md:sui-pb-0 sui-items-center sui-pr-6">
          <div className="sui-flex sui-items-center sui-pr-1">
            <DeliveryExpress size="small" />
          </div>
          <Typography variant="body-base">Delivery Date &amp; Cost</Typography>
        </div>
        <div className="sui-flex sui-pb-4 md:sui-pb-0 sui-items-center sui-pr-6">
          <div className="sui-flex sui-items-center sui-pr-1">
            <Assemble size="small" />
          </div>
          <Typography variant="body-base">Installation &amp; Parts</Typography>
        </div>
        <div className="sui-flex sui-pb-4 md:sui-pb-0 sui-items-center sui-pr-6">
          <div className="sui-flex sui-items-center sui-pr-1">
            <Apron size="small" />
          </div>
          <Typography variant="body-base">Haul Away</Typography>
        </div>
      </div>
    </div>
  </div>
);
DeliveryInfo.propTypes = {
  deliveryExpress: PropTypes.bool
};

DeliveryInfo.defaultProps = {
  deliveryExpress: true
};
