import React, { useContext } from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import QAContext from '../../context/QAContext';

// TODO: This placeholder height seems low & there's no differentiation between DT & MW
export const AccordionBodyPlaceholder = () => {
  const { channel } = useContext(QAContext);
  const maxNumberOfQuestions = channel.toLowerCase() === 'mobile' ? 8 : 4;

  return (
    <div data-component="QuestionsAndAnswersAccordionBodyPlaceholder" style={{ width: '100%' }}>
      <Placeholder height="75px" width="100%" />
      <div style={{ 'margin-top': '7px' }} />
      <Placeholder height="50px" width="100%" />
      {new Array(maxNumberOfQuestions).fill('').map((__, key) => (
        <Placeholder key={`text-${key}`} height="99px" type="text" />
      ))}
    </div>
  );
};

AccordionBodyPlaceholder.displayName = 'QuestionsAndAnswersAccordionBodyPlaceholder';