import React from 'react';
import { func, string, bool } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';

export const QAModalFormSuccess = ({ from, questionText, closeModal }) => {
  return (
    <>
      <div className="qa-modal__header">
        <h2>{questionText ? 'Thanks For Your Answer' : 'Thanks For Your Question'}</h2>
        <button type="button" onClick={closeModal}>
          <Image
            src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
            alt="Close"
          />
        </button>
      </div>
      <div className="qa-modal">
        <div className="qa-modal_success-screen">
          <p className="qa-modal_success-screen_content">
            Your {from} has been submitted successfully. Please note that your {from} may take up to
            24 hours to appear.
          </p>
        </div>
      </div>
    </>
  );
};

QAModalFormSuccess.displayName = 'QAModalFormSuccess';

QAModalFormSuccess.propTypes = {
  closeModal: func,
  from: string,
  questionText: string,
};

QAModalFormSuccess.defaultProps = {
  closeModal: null,
  from: 'question',
  questionText: '',
};
