import { useContext, useEffect } from 'react';
import QAContext from '../../context/QAContext';
import {
  QUESTION_DISPLAY_MOBILE,
  QUESTION_PER_PAGE_DESKTOP,
  DEFAULT_NUMBER_OF_PAGES
} from '../../constants';
import { getPrevPage, getNextPage, getDesktopPage2StartIndex } from '../../helpers/QAHelper';

export const useSEO = (qaResult) => {
  const { channel, dispatch, seoPageNumber, enableExperienceProperty } = useContext(QAContext);

  const isMobile = channel === 'mobile';
  const desktopPage2StartIndex = getDesktopPage2StartIndex(enableExperienceProperty);
  useEffect(() => {
    if (seoPageNumber) {
      dispatch({ action: 'SET_CURRENT_PAGE', value: seoPageNumber });

      if (seoPageNumber === DEFAULT_NUMBER_OF_PAGES) {
        dispatch({ action: 'SET_START_INDEX', value: DEFAULT_NUMBER_OF_PAGES });
      } else {
        const newStartIndex = !isMobile ? desktopPage2StartIndex + (seoPageNumber - 2) * QUESTION_PER_PAGE_DESKTOP
          : QUESTION_DISPLAY_MOBILE * (seoPageNumber - DEFAULT_NUMBER_OF_PAGES) + DEFAULT_NUMBER_OF_PAGES;
        dispatch({ action: 'SET_START_INDEX', value: newStartIndex });
      }
    }
  }, []);

  return {
    seoResults: qaResult?.data?.Results,
    seoAnswers: qaResult?.data?.Answers,
    seoTotalResults: qaResult?.data?.TotalResults,
    seoPrevPage: getPrevPage(qaResult?.data?.TotalResults, qaResult?.data?.Offset, qaResult?.data?.Limit),
    seoNextPage: getNextPage(qaResult?.data?.TotalResults, qaResult?.data?.Offset, qaResult?.data?.Limit)
  };
};
