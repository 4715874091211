import React, { useContext, useState, Suspense } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { CustomerImageOverlay } from '@thd-olt-component-react/customer-image-overlay';
import { triggerAnalytics } from '../../../Analytics';
import QAContext from '../../../context/QAContext';

export const MediaCarousel = ({ photos, question, uploadedClassName }) => {
  const [previewOverlayOpen, setPreviewOverlayOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const { channel } = useContext(QAContext);

  const onOverlayInteraction = () => {
    const payload = {
      origin: 'questions and answers',
      location: 'media',
      value: 'gallery',
      eventType: 'click'
    };
    triggerAnalytics(payload);
  };

  return (
    <div className="photo-carousel">
      <div className="photo-carousel__photos">
        {photos?.length > 0 && photos.map((photo, photoIndex) => {
          const { Sizes } = photo;
          const thumbnail = Sizes ? `url(${Sizes?.thumbnail?.Url})` : '';

          return (
            <div
              key={`${photoIndex}`}
              className={'photo-carousel__photo ' + uploadedClassName}
            >
              <button
                type="button"
                aria-label="photo"
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: (thumbnail)
                }}
                onClick={() => {
                  setCurrentMedia(photo);
                  setPreviewOverlayOpen(true);
                }}
              />
            </div>
          );
        })}
      </div>

      {previewOverlayOpen && currentMedia && (
        <Suspense fallback={(<div />)}>
          <Overlay
            medium={channel !== 'mobile'}
            modalClass="qna-uploaded-photo__overlay"
            open={previewOverlayOpen}
            onClose={() => setPreviewOverlayOpen(false)}
          >
            <div className="qna-show-image__wrapper">
              {previewOverlayOpen && (
                <Suspense fallback={(<div />)}>
                  <CustomerImageOverlay
                    channel={channel}
                    photos={typeof (photos) !== 'undefined' && photos != null ? photos : []}
                    selectedPhotoID={currentMedia.Id}
                    loopPhotos={photos?.length > 1}
                    onInteraction={onOverlayInteraction}
                    isQuestionsAndAnswerPhotos
                    question={question}
                    onClose={() => setPreviewOverlayOpen(false)}
                  />
                </Suspense>
              )}
            </div>
          </Overlay>
        </Suspense>
      )}
    </div>
  );
};

MediaCarousel.displayName = 'MediaCarousel';

MediaCarousel.propTypes = {
  photos: arrayOf(shape({})),
  uploadedClassName: string,
  question: string
};

MediaCarousel.defaultProps = {
  photos: [],
  uploadedClassName: '',
  question: ''
};
