import React, { useState, useEffect } from 'react';
import {
  string, func, bool, element, shape
} from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Image } from '@thd-olt-component-react/core-ui';
import { SwapDrawer } from './sub-components/swap-drawer/SwapDrawer';
import styles from './swap-item.module.scss';
import { dataModel } from './sub-components/swap-drawer/dataModel';

const SwapItem = ({
  itemId,
  onSwap,
  closeOnSwap,
  className,
  promoId,
  disabled,
  hideIcon,
  isDark,
  btnContent,
  customElement,
  selectionButtonContent,
  hideCurrentSelectedItem,
  currentItemSelectable,
  isSmall,
  openInQuickViewDescription,
  openDetailsInNewTab,
  hideQuickViewMask,
  itemIndex,
  drawerRootProps,
  openCurrentItemInQuickView
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('swap-item.ready');
  }, []);
  const [openSwapDrawer, setOpenSwapDrawer] = useState(false);
  const [fetch, setFetch] = useState(false);

  const handleMouseEnter = (event) => {
    event.preventDefault();
    if (!disabled) {
      setFetch(true);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    setOpenSwapDrawer(true);
    handleMouseEnter(event);
  };

  const handleSwap = (product) => {
    onSwap(product);
    if (closeOnSwap) {
      setOpenSwapDrawer(false);
    }
  };

  const imageClass = classNames(styles['swap-item__bttn-img']);

  return (
    <div className={`swap-item ${className}`} data-testid="swap-item" data-component="SwapItem">
      {customElement ? (
        <div
          onClick={handleClick}
          role="button"
          tabIndex="0"
          onMouseEnter={handleMouseEnter}
        >
          {customElement}
        </div>
      ) : (
        <Button
          dark={isDark}
          outline
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          disabled={disabled}
          small={isSmall}
        >
          {!hideIcon && (
            <Image
              src="https://assets.thdstatic.com/images/v1/swap-icon.svg"
              alt="Swap Item"
              className={imageClass}
            />
          )}
          {btnContent}
        </Button>
      )}
      <SwapDrawer
        openSwapDrawer={openSwapDrawer}
        itemId={itemId}
        fetch={fetch}
        onClose={() => {
          setOpenSwapDrawer(false);
        }}
        onSwap={handleSwap}
        promoId={promoId}
        hideCurrentSelectedItem={hideCurrentSelectedItem}
        selectionButtonContent={selectionButtonContent}
        currentItemSelectable={currentItemSelectable}
        openInQuickViewDescription={openInQuickViewDescription}
        openDetailsInNewTab={openDetailsInNewTab}
        hideQuickViewMask={hideQuickViewMask}
        itemIndex={itemIndex}
        drawerRootProps={drawerRootProps}
        openCurrentItemInQuickView={openCurrentItemInQuickView}
      />
    </div>
  );
};

SwapItem.propTypes = {
  itemId: string.isRequired,
  onSwap: func.isRequired,
  promoId: string,
  className: string,
  disabled: bool,
  closeOnSwap: bool,
  hideIcon: bool,
  isDark: bool,
  btnContent: string,
  customElement: element,
  hideCurrentSelectedItem: bool,
  selectionButtonContent: string,
  isSmall: bool,
  currentItemSelectable: bool,
  openInQuickViewDescription: bool,
  openDetailsInNewTab: bool,
  hideQuickViewMask: bool,
  itemIndex: string,
  drawerRootProps: shape({}),
  openCurrentItemInQuickView: bool
};

SwapItem.defaultProps = {
  className: '',
  promoId: null,
  disabled: false,
  closeOnSwap: false,
  hideIcon: false,
  isDark: true,
  btnContent: 'Swap Model',
  customElement: null,
  hideCurrentSelectedItem: false,
  selectionButtonContent: null,
  isSmall: false,
  currentItemSelectable: false,
  openInQuickViewDescription: false,
  openDetailsInNewTab: false,
  hideQuickViewMask: false,
  itemIndex: 'n/a',
  drawerRootProps: {},
  openCurrentItemInQuickView: false
};

SwapItem.displayName = 'SwapItem';

SwapItem.dataModel = dataModel;

export { SwapItem };
