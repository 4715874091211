import React, { useContext } from 'react';
import { shape } from 'prop-types';
import { Button } from '@thd-olt-component-react/core-ui';
import { SwapContext } from '../../SwapContext';

const SelectionButton = ({ selectedProduct }) => {
  const { onSwap, currentItem, selectionButtonContent } = useContext(SwapContext);
  const calculateDiff = () => {
    const diff = currentItem?.pricing?.value - selectedProduct?.pricing?.value;
    if (diff === 0 || currentItem?.pricing?.mapAboveOriginalPrice !== null) return '';
    return diff > 0 ? `(-$${diff.toFixed(2)})` : `(+$${Math.abs(diff).toFixed(2)})`;
  };

  const getPriceDifference = () => (
    selectedProduct?.pricing?.mapAboveOriginalPrice !== null ? '' : calculateDiff()
  );

  return (
    <Button
      text="Select"
      outline
      data-testid="select-button"
      onClick={() => {
        onSwap({ product: selectedProduct });
      }}
    >
      {selectionButtonContent || `Select ${getPriceDifference()}`}
    </Button>
  );
};

SelectionButton.propTypes = {
  selectedProduct: shape({}).isRequired
};

SelectionButton.displayName = 'SelectionButton';

export { SelectionButton };
