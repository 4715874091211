import { useContext, useEffect, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import {
  QUESTION_DISPLAY_MOBILE,
  QUESTION_DISPLAY_DESKTOP,
  QUESTION_PER_PAGE_DESKTOP,
  DEFAULT_NUMBER_OF_PAGES,
} from '../../constants';
import QAContext from '../../context/QAContext';
import { getDesktopPage2StartIndex } from '../../helpers/QAHelper';

export const useQAResult = () => {
  let {
    itemId,
    searchText,
    sortBy,
    startIndex,
    dispatch,
    channel,
    currentPage,
    seoPageNumber,
    enableExperienceProperty
  } = useContext(QAContext);
  const isMobile = channel === 'mobile';
  const desktopPage2StartIndex = getDesktopPage2StartIndex(enableExperienceProperty);
  let desktopPageSize = '';

  if (enableExperienceProperty) {
    desktopPageSize = currentPage === 1 ? QUESTION_DISPLAY_DESKTOP : QUESTION_PER_PAGE_DESKTOP;
  } else {
    desktopPageSize = currentPage === 1 ? 4 : QUESTION_PER_PAGE_DESKTOP;
  }
  let pageSize = isMobile ? QUESTION_DISPLAY_MOBILE : desktopPageSize;

  const shouldUseSEOResults = useRef({});
  // This will only run if JS is disabled
  if (seoPageNumber !== null && shouldUseSEOResults?.current) {
    if (enableExperienceProperty) {
      desktopPageSize = seoPageNumber === 1 ? QUESTION_DISPLAY_DESKTOP : QUESTION_PER_PAGE_DESKTOP;
    } else {
      desktopPageSize = seoPageNumber === 1 ? 4 : QUESTION_PER_PAGE_DESKTOP;
    }
    pageSize = isMobile ? QUESTION_DISPLAY_MOBILE : desktopPageSize;

    if (seoPageNumber === 1) {
      startIndex = 1;
    } else if (!isMobile) {
      startIndex = desktopPage2StartIndex + (seoPageNumber - 2) * QUESTION_PER_PAGE_DESKTOP;
    } else {
      startIndex = QUESTION_DISPLAY_MOBILE * (seoPageNumber - DEFAULT_NUMBER_OF_PAGES) + DEFAULT_NUMBER_OF_PAGES;
    }
  }

  const QandA = useDataModel('questionsAnswers', {
    variables: {
      itemId,
      startindex: startIndex,
      pagesize: pageSize,
      searchTerm: searchText,
      sortBy
    },
  });

  const qaData = QandA?.data;
  const qaError = QandA?.error;
  const qaLoading = QandA?.loading;
  const qaSeoData = {
    data: {
      Results: qaData?.questionsAnswers?.Results,
      Answers: qaData?.questionsAnswers?.Includes?.Answers,
      TotalResults: qaData?.questionsAnswers?.TotalResults,
      Offset: qaData?.questionsAnswers?.Offset,
      Limit: qaData?.questionsAnswers?.Limit
    }
  };

  useEffect(() => {
    dispatch({
      action: 'SET_LOADING',
      value: qaLoading
    });
    if (qaData?.questionsAnswers && !qaLoading && !qaError) {
      dispatch({
        action: 'SET_RESULTS',
        value: {
          TotalResults: qaData?.questionsAnswers?.TotalResults,
          Results: qaData?.questionsAnswers?.Results,
          Answers: qaData?.questionsAnswers?.Includes?.Answers,
          qaLoading
        }
      });
    }
  }, [itemId, startIndex, pageSize, searchText, sortBy, qaLoading]);

  if (seoPageNumber && shouldUseSEOResults?.current) {
    shouldUseSEOResults.current = false;
    return qaSeoData;
  }

  return null;
};
