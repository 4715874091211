import React, { Component } from 'react';
import { func } from 'prop-types';

class DeviceDetection extends Component {
  static URI = 'https://mpsnare.iesnare.com/snare.js';

  static id = 'blackBox';

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      fp: ''
    };
  }

  async componentDidMount() {
    const { onError } = this.props;
    try {
      this.setGlobals();
      await this.loadScript();
      const fp = this.ref.current.value;
      this.setState({ fp });
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { fp } = this.state;
    const { onSuccess } = this.props;
    if (fp && previousState.fp !== fp && onSuccess) {
      onSuccess(fp);
    }
  }

  setGlobals = () => {
    if (typeof window !== 'undefined' && window) {
      window.io_bbout_element_id = DeviceDetection.id;
      window.io_install_stm = false;
      window.io_exclude_stm = 12;
      window.io_install_flash = false;
      window.io_enable_rip = true;
    }
  };

  loadScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = resolve;
      script.onerror = reject;
      script.async = true;
      script.src = DeviceDetection.URI;
      document.body.appendChild(script);
    });
  };

  render() {
    const { fp } = this.state;
    return <input ref={this.ref} type="hidden" id={DeviceDetection.id} value={fp} data-testid="DeviceDetectionComp" />;
  }
}

DeviceDetection.displayName = 'RatingsAndReviewsDeviceDetection';

DeviceDetection.propTypes = {
  onSuccess: func,
  onError: func
};

DeviceDetection.defaultProps = {
  onSuccess: null,
  onError: null
};

export { DeviceDetection };