import React from 'react';
import { Apron, Secure, Returns, Savings } from '@one-thd/sui-icons';
import WhatWeOfferItem from './WhatWeOfferItem';

const whatWeOfferItems = [
  {
    description:
      'We offer move or haul away services, and installation on most appliances when you purchase required parts.',
    titleLine1: 'Delivery',
    titleLine2: 'Services',
    icon: <Apron size="large" />,
    linkUrl: '/c/Appliance_Delivery_Installation',
    linkText: 'Installation Requirements',
  },
  {
    description: 'Get 24/7 support and our 3-day Service Guarantee',
    titleLine1: 'The Home Depot',
    titleLine2: 'Protection Plan',
    icon: <Secure size="large" />,
    linkUrl: '/c/Home_Depot_Protection_Plan_Coverage_Details#majorappliances',
    linkText: 'Plan Coverage and Details',
  },
  {
    description: 'You can return your appliance for damages and defects within 48 hours.',
    titleLine1: '48-Hour',
    titleLine2: 'Return Policy',
    icon: <Returns size="large" />,
    linkUrl: '/c/Return_Policy',
    linkText: 'Return Policy',
  },
  {
    description:
      'We will match prices including shipping of any other online competitor.',
    titleLine1: 'Price Match',
    titleLine2: 'Guarantee',
    icon: <Savings size="large" />,
    linkUrl: '/c/PM_New_Lower_Price',
    linkText: 'Learn More',
  }
];

export const WhatWeOfferContent = () => (
  <div className="sui-grid sm:sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-4 sui-gap-8">
    {whatWeOfferItems.map((item, i) => (
      <WhatWeOfferItem
        key={i}
        description={item.description}
        titleLine1={item.titleLine1}
        titleLine2={item.titleLine2}
        icon={item.icon}
        linkUrl={item.linkUrl}
        linkText={item.linkText}
      />
    ))}
  </div>

);

WhatWeOfferContent.displayName = 'WhatWeOfferContent';

WhatWeOfferContent.propTypes = {};

WhatWeOfferContent.defaultProps = {};
