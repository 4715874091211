import React, { useEffect, useRef } from 'react';
import { arrayOf as propTypeArrayOf, shape as shapeProp } from 'prop-types';
import {
  params, string, arrayOf, extend, shape, bool
} from '@thd-nucleus/data-sources';
import { trackAnalytics } from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {product} product - Bundle parent product
 * @property {products} products - Bundle products
 */

const BundlePageAnalytics = ({ product, products }) => {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (product && products?.length) {
      if (!calledOnce.current) {
        const output = {
          product,
          products
        };
        trackAnalytics('bip.page-load', output);
        calledOnce.current = true;
      }
    }
  }, [product, products]);

  return (<></>);
};

BundlePageAnalytics.propTypes = {
  product: shapeProp({}),
  products: propTypeArrayOf(shapeProp())
};

BundlePageAnalytics.defaultProps = {
  product: null,
  products: null
};

BundlePageAnalytics.displayName = 'BundlePageAnalytics';

BundlePageAnalytics.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      taxonomy: shape({
        breadCrumbs: arrayOf({
          browseUrl: string(),
          creativeIconUrl: string(),
          deselectUrl: string(),
          dimensionName: string(),
          label: string(),
          refinementKey: string(),
          url: string()
        })
      }),
      bundleSpecificationDetails: shape({
        experienceType: string(),
        type: string()
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      identifiers: shape({
        brandName: string(),
        productLabel: string(),
        productType: string()
      }),
      availabilityType: shape({
        discontinued: bool(),
        type: string()
      }),
      fulfillment: {
        anchorStoreStatusType: string(),
        onlineStoreStatusType: string(),
        backordered: bool(),
        fulfillmentOptions: shape({
          fulfillable: bool(),
          services: shape({
            type: string(),
            deliveryDates: shape({
              endDate: string(),
              startDate: string()
            }),
            locations: shape({
              type: string(),
              isAnchor: bool(),
              inventory: shape({
                isInStock: bool(),
                isOutOfStock: bool(),
                isLimitedQuantity: bool()
              })
            })
          }),
          type: string()
        })
      }
    })
  }
);

export { BundlePageAnalytics };