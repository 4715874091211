import React, { useState, useContext } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { Button } from '@thd-olt-component-react/core-ui';
import { QAModal } from '../QAModalForm/QAModalForm.component';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import './AskAQuestion.scss';

export const AskAQuestion = () => {
  const { channel, itemId, TotalResults, searchText } = useContext(QAContext);

  const [askAQnOverlayOpen, setAskAQnOverlayOpen] = useState(false);
  const buttonClass = classNames('ask-a-question-container__button', {
    'ask-a-question-container__button-no-qns': TotalResults === 0 && (channel === 'desktop' || channel === 'tablet'),
    'ask-a-question-container__button-no-qns__mobile': TotalResults === 0 && channel === 'mobile',
  });

  const buttonText = TotalResults === 0 && !searchText ? 'Ask the First Question' : 'Ask a Question';

  const handleOnClick = () => {
    setAskAQnOverlayOpen(true);

    const payload = { component: 'ask a new question' };
    triggerAnalytics(payload);
  };

  return (
    <>
      <div className={TotalResults === 0 ? 'ask-a-question-container' : ''}>
        <Button outline inline className={buttonClass} onClick={handleOnClick}>
          {buttonText}
        </Button>
      </div>
      {askAQnOverlayOpen && (
        <QueryProvider cacheKey="ask-a-question-form">
          <QAModal
            itemId={itemId}
            channel={channel}
            openModal={askAQnOverlayOpen}
            headerText="Ask a Question"
            closeModal={() => setAskAQnOverlayOpen(false)}
          />
        </QueryProvider>
      )}
    </>
  );
};

AskAQuestion.displayName = 'AskAQuestion';
