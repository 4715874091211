import { useState, useEffect, useContext, useRef } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { filterRemovedProducts, getPriceAdjustment, onLifeCycleEvent,
  offLifeCycleEvent } from '../utils/product-bundle-utils';
import { PRODUCTS_CHANGED } from '../constants';

const usePriceAdjustment = (products) => {
  const experienceContext = useContext(ExperienceContext);
  const { hosts } = experienceContext;
  const { globalCustomConfiguratorsBaseUrl: priceAdjustmentUrl } = hosts;
  const [adjustedPricing, setAdjustedPricing] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const updatePriceAdjustment = async (productsData) => {
    const adjustedPrice = await getPriceAdjustment(productsData, priceAdjustmentUrl);
    setAdjustedPricing(adjustedPrice);
  };

  useEffect(() => {
    onLifeCycleEvent(PRODUCTS_CHANGED, (event) => {
      updatePriceAdjustment(filterRemovedProducts(event.output));
    });
    return () => {
      offLifeCycleEvent(PRODUCTS_CHANGED);
    };
  }, []);

  useEffect(() => {
    if (products?.length && !isInitialized) {
      setIsInitialized(true);
      updatePriceAdjustment(products);
    }
  }, [products, isInitialized]);

  return adjustedPricing;
};

export default usePriceAdjustment;
