import thdCustomer from '@thd-olt-global/thd-customer';

export const isNotAuthenticated = () => {
  return (thdCustomer.isKMSI && thdCustomer.isPersisted) || !thdCustomer.isLoggedIn;
};

export const getUserName = () => {
  return thdCustomer?.username || null;
};

export const isCommercial = (accountType) => {
  return accountType === 'PLNP_HOMEDEPOT' || accountType === 'PLCR_HOMEDEPOT';
};

export const ssoExists = (response) => {
  return (
    response.ssoExist === true
    && response.accounts
    && Array.isArray(response.accounts)
    && response.accounts.length >= 1
  );
};

export const getDefaultCard = (accounts) => {
  const defaultCards = accounts.filter((account) => account.defaultCard === true);
  const consumerDefaultCard = defaultCards.filter(
    (account) => account.accountType === 'PLCN_HOMEDEPOT'
  );
  if (consumerDefaultCard.length > 0) {
    return consumerDefaultCard[0];
  }
  if (defaultCards.length > 0) {
    return defaultCards[0];
  }
  return accounts[0];
};

export const unauthorizedError = (response) => {
  return (
    response.errors
    && Array.isArray(response.errors)
    && response.errors[0].errorCode === 'AUTH_ERR_105'
  );
};

export const isErrorResponse = (response) => {
  return (
    response.ssoExist === false
    && response.errors
    && response.errors.errors
    && Array.isArray(response.errors.errors)
  );
};

export const accountUnlinkedDueToInactivity = (response) => {
  return (
    response.ssoExist === false
    && response.accounts
    && Array.isArray(response.accounts)
    && response.accounts[0].errors
    && Array.isArray(response.accounts[0].errors.errors)
    && response.accounts[0].errors.errors[0].errorCode === 'SSO_CITI_401'
  );
};

export const plccCardSaved = (plccCardResponse, cardType) => {
  let plccCardExists = false;

  if (plccCardResponse.savedCards && plccCardResponse.savedCards.length >= 1) {
    const plccCard = plccCardResponse.savedCards.filter((card) => {
      return card === cardType;
    });
    if (plccCard.length >= 1) plccCardExists = true;
  }

  return plccCardExists;
};

export const defaultCard401CitiError = (defaultCard) => {
  return (
    defaultCard.errors
    && defaultCard.errors.errors
    && Array.isArray(defaultCard.errors.errors)
    && defaultCard.errors.errors[0].errorCode === 'SSO_CITI_401'
  );
};

export const formatPrice = (val, noDollar = false) => {
  let str;
  if (typeof val === 'string') {
    str = Math.abs(parseFloat(val));
  } else {
    str = Math.abs(val);
  }
  // Should this be here, this will round up !!!!!!
  str = parseFloat(str.toFixed('2'));
  str = str.toLocaleString();
  if (str.indexOf('.') === -1) {
    str += '.00';
  } else if (/\.\d$/.test(str)) {
    str += '0';
  }
  if (noDollar) {
    return str;
  }
  return '$' + str;
};

export const getDynamicOffer = (priceNum) => {
  if (priceNum > 299.99 && priceNum < 1000) {
    return 50;
  }
  if (priceNum > 999.99) {
    return 100;
  }
  return 25;
};