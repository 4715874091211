import React from 'react';
import PropTypes from 'prop-types';
import { getProductImage } from '../../utils/product-bundle-utils';

const wrapperClassName = 'sui-w-16 sui-h-16 sui-flex-auto sui-shrink-0 sui-grow-0';

export const ProductListImage = (props) => {
  const { product, openQuickView } = props;
  const { quickView } = product?.features ?? {};

  const img = (
    <img
      src={getProductImage(product?.media?.images)}
      alt={product?.identifiers?.productLabel}
      width="100%"
      height="100%"
    />
  );

  if (quickView) {
    return (
      <button
        className={wrapperClassName}
        data-testid="product-list-product-image"
        onClick={() => openQuickView(product)}
        type="button"
      >
        {img}
      </button>
    );
  }

  return (
    <div className={wrapperClassName}>
      {img}
    </div>
  );
};

ProductListImage.propTypes = {
  product: PropTypes.shape({
    media: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          sizes: PropTypes.arrayOf(PropTypes.string),
          url: PropTypes.string
        })
      )
    }),
    identifiers: PropTypes.shape({
      productLabel: PropTypes.string
    }),
    features: PropTypes.shape({
      quickView: PropTypes.bool
    })
  }).isRequired,
  openQuickView: PropTypes.func.isRequired
};
