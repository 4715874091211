import React, { useEffect, useContext, useState, useMemo } from 'react';
import {
  arrayOf as propTypeArrayOf,
  shape as shapeProp,
  string as propTypeString
} from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { BuyboxActions } from '@thd-olt-component-react/buybox/dist/component/subcomponents/BuyboxActions';
import { BuyboxMessage } from '@thd-olt-component-react/buybox/dist/component/subcomponents/BuyboxMessage';
import {
  ExcludedShipState
} from '@thd-olt-component-react/fulfillment/dist/components/tiles/ExcludedShipState/ExcludedShipState';
import {
  params, string, arrayOf, bool, shape, extend
} from '@thd-nucleus/data-sources';
import { isPaypalEligible } from '@thd-olt-component-react/buybox/dist/BuyboxHelper';
import BuyboxContainer from './subcomponents/BuyboxContainer';
import { BundleMessage } from './subcomponents/BundleMessage';
import useProductsFullfilmentValidation from '../hooks/useProductsFullfilmentValidation';
import useGetExcludedShipState from '../hooks/useGetExcludedShipState';
import useIsProductsErrorNotAvailable from '../hooks/useIsProductsErrorNotAvailable';
import useGetCartItems from '../hooks/useGetCartItems';
import {
  bundleAddToCart, filterRemovedProducts, getB2BUser, getB2CUser
  , getBundleFulfillment, getPaypalCartItems, getProductsDefaultValue, isReturnMessagesEligible
} from '../utils/product-bundle-utils';
import { BundleFulfillment } from './BundleFulfillment';
import { OUT_OF_STOCK } from '../constants';

import '@thd-olt-component-react/buybox/dist/component/buybox.scss';
import '../styles/BundleBuybox.scss';

import { DeliveryInfo } from './subcomponents/DeliveryInfo';
import { BundleQuantityMessaging } from './subcomponents/BundleQuantityMessaging';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {Product[]} products Array of products
 * @property {String} itemId parent itemid
 */

/**
 * Returns a component, BundleSummary, showing the total price and
 * discounts applied to the bundle.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

export const BundleBuybox = ({ product, products, itemId, productsError }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-buybox.ready');
  }, []);

  const activeProducts = useMemo(() => filterRemovedProducts(products), [products]);

  const experienceContext = useContext(ExperienceContext);
  const { store, isConsumerApp } = experienceContext;
  const storeId = store?.storeId;
  const storeZip = store?.storeZip;
  const deliveryZip = experienceContext?.deliveryZip;
  const bundleId = product?.itemId;
  const [bundleQuantity, setBundleQuantity] = useState(1);
  const { producstErrorNotAvailable } = useIsProductsErrorNotAvailable({
    error: productsError, products: activeProducts
  });
  const {
    bundleMaxQuantityLimit,
    disableATC,
    productsFulfillmentInformation,
    currentlyOOS,
    fulfillmentUnavailable
  } = useProductsFullfilmentValidation({ products: activeProducts, deliveryZip, bundleQuantity, storeZip });
  const { cartItems } = useGetCartItems({
    productsFulfillmentInformation, bundleQuantity, storeId, storeZip, bundleId
  });
  const {
    excludedShipState,
    showCheckAvailability,
    setShowCheckAvailability
  } = useGetExcludedShipState({ products: activeProducts, deliveryZip, storeZip });

  const onQuantityChange = (event) => setBundleQuantity(event?.quantity);
  const bundleAtcClickPassThrough = () => bundleAddToCart({ cartItems, storeId, storeZip });

  const isPaypalB2BEnabled = useConfigService('PAYPAL_ENABLE_B2B');
  const paypalEligible = isPaypalEligible(getB2BUser(), getB2CUser(), isPaypalB2BEnabled);
  const showPaypalSmartButton = useConfigService('PAYPAL_ENABLE');
  const isRisMessageEnabled = useConfigService('fs:isRisMessageEnabled');
  const isReturnMessageEligible = isReturnMessagesEligible(activeProducts);

  const { value } = getProductsDefaultValue(activeProducts);

  const fulfillmentMethod = cartItems?.[0]?.fulfillmentMethod;

  const {
    quantity, paypalCheckout, addToCart, deliveryInfo, deliveryExpress, showUnavailableMessage
  } = product?.features ?? {};

  const isDisabledATC = activeProducts?.length === 0 || (addToCart && disableATC);

  const paypalCartItems = useMemo(() => {
    return getPaypalCartItems({ cartItems, deliveryZip });
  }, [cartItems]);

  const fulfillmentOptions = useMemo(() => {
    return getBundleFulfillment({ products: activeProducts });
  }, [activeProducts]);

  if (showUnavailableMessage) {
    if (excludedShipState?.isExcluded) {
      return (
        <BuyboxContainer>
          <ExcludedShipState
            stateText={excludedShipState?.storeStateName}
            itemId={itemId}
            showCheckAvailability={showCheckAvailability}
            setShowCheckAvailability={setShowCheckAvailability}
          />
        </BuyboxContainer>
      );
    }
    if (fulfillmentUnavailable || producstErrorNotAvailable || currentlyOOS) {
      return (
        <BuyboxContainer>
          <BundleMessage messageRequest={OUT_OF_STOCK} />
        </BuyboxContainer>
      );
    }
  }

  return (
    <BuyboxContainer>
      {isRisMessageEnabled && isReturnMessageEligible && (
        <div data-testid="return-messaging">
          <BuyboxMessage
            message="This bundle is only returnable in store."
            status="info"
          />
        </div>
      )}
      <div className={'buybox-wrapper' + (!quantity ? ' buybox-hide-quantity' : '')}>
        <BundleQuantityMessaging
          maxQuantity={bundleMaxQuantityLimit?.quantityLimit}
          quantity={bundleQuantity}
        />
        <BuyboxActions
          itemId={itemId}
          configurableItems={cartItems}
          onQuantityChange={onQuantityChange}
          quantity={bundleQuantity}
          onClickPassThrough={bundleAtcClickPassThrough}
          paypalEligible={paypalEligible}
          hideInstantCheckout
          sticky={!isConsumerApp}
          hideQuantity={!quantity}
          hidePaypalCheckout={!paypalCheckout || !showPaypalSmartButton}
          disableATC={isDisabledATC}
          fulfillment={fulfillmentOptions}
          bipCartItems={paypalCartItems}
          itemPrice={value}
          fulfillmentMethod={fulfillmentMethod}
        />
        <div className="sui-mt-6">
          {
            deliveryInfo
              ? <DeliveryInfo deliveryExpress={deliveryExpress} />
              : <BundleFulfillment products={activeProducts} />
          }
        </div>
      </div>
    </BuyboxContainer>
  );
};

BundleBuybox.propTypes = {
  product: shapeProp({
    bundleSpecificationDetails: shapeProp({
      type: propTypeString
    })
  }).isRequired,
  products: propTypeArrayOf(
    shapeProp({
      fulfillment: shapeProp(),
      info: shapeProp()
    })
  ).isRequired,
  itemId: propTypeString.isRequired,
  productsError: shapeProp({})
};

BundleBuybox.defaultProps = {
  productsError: null
};

BundleBuybox.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      bundleSpecificationDetails: shape({
        type: string()
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      info: shape({
        quantityLimit: string(),
        isInStoreReturnMessageEligible: bool(),
        returnable: string()
      }),
      fulfillment: {
        anchorStoreStatusType: string(),
        backordered: bool(),
        backorderedShipDate: string(),
        bossExcludedShipStates: string(),
        bodfsAssemblyEligible: bool(),
        seasonStatusEligible: bool(),
        excludedShipStates: string(),
        fulfillmentOptions: shape({
          fulfillable: bool(),
          services: shape({
            type: string(),
            isDefault: bool(),
            deliveryCharge: string(),
            deliveryDates: shape({
              endDate: string(),
              startDate: string()
            }),
            deliveryTimeline: string(),
            freeDeliveryThreshold: string(),
            totalCharge: string(),
            locations: shape({
              type: string(),
              isAnchor: bool(),
              locationId: string(),
              storeName: string(),
              storePhone: string(),
              state: string(),
              distance: string(),
              inventory: shape({
                quantity: string(),
                isInStock: bool(),
                isOutOfStock: bool(),
                isLimitedQuantity: bool(),
                isUnavailable: bool(),
                maxAllowedBopisQty: string(),
                minAllowedBopisQty: string()
              })
            })
          }),
          type: string()
        })
      }
    })
  },
  BuyboxActions
);

BundleBuybox.displayName = 'BundleBuybox';
