import React from 'react';
import PropTypes from 'prop-types';

import {
  QueryProvider, useDataModel, params, string, extend
} from '@thd-nucleus/data-sources';

import { BundleMiniContent as BundleMiniContentBase } from './subcomponents/BundleMiniContent';
import { withBundleProducts } from './WithBundleProducts';

const BundleMiniContent = withBundleProducts(BundleMiniContentBase);

const BundleMini = ({ itemId, storeId }) => {
  const { data } = useDataModel('product', {
    skip: !itemId,
    variables: {
      itemId
    }
  });

  const displayMini = !!data;

  if (!displayMini) {
    return null;
  }

  return (
    <div data-testid="bundle-mini">
      <QueryProvider cacheKey="bundle-mini">
        <BundleMiniContent
          bundleId="329611046"
          storeId={storeId}
        />
      </QueryProvider>
    </div>
  );
};

BundleMini.displayName = 'BundleMini';

BundleMini.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired
};

BundleMini.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      itemId: string(),
      dataSources: string()
    })
  }
);

export { BundleMini, BundleMiniContent };