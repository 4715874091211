import React from 'react';
import { shape, number, bool } from 'prop-types';
import { Typography, Tooltip } from '@one-thd/sui-atomic-components';
import { SpecialSavings } from '@one-thd/sui-icons';
import { TOOLTIP_VIEW_DETAILS } from '../../constants';
import { formatPrice } from '../../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {array} calculations An object of Adjusted Pricing
 * @property {bool} showBundleSavings A bool to show svaings
 */

/**
 * Returns The Delivery Fee and Tax Line Items based on several conditions.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const AdditionalSavingsContent = ({ calculations, showBundleSavings }) => {

  const promos = showBundleSavings ? Object.values(calculations.bundleSavingsByPromos)
    : [
      {
        promoId: 0,
        savings: calculations?.bundleSavings,
        shortDesc: 'Bundle Savings',
        percentageOff: `(${Math.round(calculations?.bundleSavingsPercentOff)}%)`
      }
    ];

  return (
    <div>
      <Typography variant="body-base" weight="bold">Additional Savings</Typography>
      {promos.map((promo) => (
        <div key={promo.promoId} className="sui-flex sui-flex-row sui-justify-between sui-mt-2">
          <div className="sui-flex sui-gap-1 sui-items-baseline">
            <SpecialSavings size="small" color="success" />
            <div className="sui-flex-1">
              <Typography variant="body-base" color="success">
                {promo.shortDesc} {promo.percentageOff}
              </Typography>
            </div>
            {promo?.longDesc
            && (
              <Tooltip offset="normal" title={promo?.longDesc}>
                <Typography
                  variant="body-xs"
                  decoration="underline"
                  data-testid="bundle-view-details"
                >
                  {TOOLTIP_VIEW_DETAILS}
                </Typography>
              </Tooltip>
            )}
          </div>
          <Typography variant="body-base" color="success" data-testid="bundle-savings-amount">
            -{formatPrice(promo.savings)}
          </Typography>
        </div>
      ))}
    </div>
  );
};

AdditionalSavingsContent.propTypes = {
  calculations: shape({
    bundleSavingsPercentOff: number,
    bundleSavings: number,
    bundleSavingsByPromos: shape({})
  }).isRequired,
  showBundleSavings: bool.isRequired
};

AdditionalSavingsContent.displayName = 'AdditionalSavingsContent';

export { AdditionalSavingsContent };
