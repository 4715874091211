/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@one-thd/sui-atomic-components';
import { formatPrice } from '../../utils/product-bundle-utils';
import { calculateTotal } from '../../utils/bundle-price-utils';

const BundleMiniPrice = ({ products }) => {
  const {
    totalValue, totalSavings, original, hasTotalSavings, percentage
  } = calculateTotal(products);

  return (
    <div data-testid="bundle-mini-price">
      <div className="sui-mb-2">
        <Typography variant="body-base" height="tight">
          Total price for {products?.length} {products?.length > 1 ? 'items' : 'item'}
        </Typography>
      </div>
      <div className="sui-flex sui-gap-2">
        {
          totalValue && (
            <div data-testid="bundle-total-price" className="sui-flex">
              <div className="[&>p]:!sui-text-3xl">
                <Typography variant="h4" component="p" weight="display" height="none">
                  $
                </Typography>
              </div>
              <div className="[&>p]:!sui-text-9xl">
                <Typography variant="h1" component="p" weight="display" height="none">
                  {totalValue.dollars}
                </Typography>
              </div>
              <div className="[&>p]:!sui-text-3xl">
                <Typography variant="h4" component="p" weight="display" height="none">
                  {totalValue.cents}
                </Typography>
              </div>
            </div>
          )
        }
        {
          !!original && hasTotalSavings && !!totalSavings && (
            <div data-testid="bundle-saving-price">
              <Typography variant="body-base" height="tight" color="subtle">
                Was <span className="sui-line-through">{formatPrice(original)}</span>
              </Typography>
              <Typography variant="body-base" height="tight" color="success">
                Save {formatPrice(totalSavings)} ({Math.round(percentage)}%)
              </Typography>
            </div>
          )
        }
      </div>
    </div>
  );
};

BundleMiniPrice.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    pricing: PropTypes.shape()
  }))
};

BundleMiniPrice.defaultProps = {
  products: []
};

BundleMiniPrice.displayName = 'BundleMiniPrice';

export { BundleMiniPrice };