import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@one-thd/sui-atomic-components';
import { GROUP_TYPES } from '../helpers';
import { AttributeContent } from './AttributeContent';

export const SpecificationGroupContent = ({ specificationGroup, oneColumn, returnableStatus, isWhiteSpecTitle }) => {
  useEffect(() => {
    if (window?.QSI?.API) {
      window.QSI.API.unload();
      window.QSI.API.load();
      window.QSI.API.run();
    }
  }, []);

  return (
    <>
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.DIMENSIONS)
        .map((attrGroupList, index) => (
          <div className="sui-flex sui-flex-wrap sui-w-full" key={`dimensions-${index}`}>
            <div className="sui-mb-4 sui-w-full">
              <Typography variant="h4">
                {attrGroupList.specTitle}
              </Typography>
            </div>
            <AttributeContent
              attributeGroupList={attrGroupList}
              oneColumn={oneColumn}
              isWhiteSpecTitle={isWhiteSpecTitle}
            />
          </div>
        ))}
      {returnableStatus
        && (
          <div className="sui-flex sui-flex-wrap sui-w-full">
            <div className="sui-w-full">
              <Alert status="info">Specifications apply to base paint. Some specifications like Color Family
                and RGB value may differ for the tinted paint mixed in store. All tinted paint is
                non-returnable.
              </Alert>
            </div>
          </div>
        )}

      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.DETAILS)
        .map((attrGroupList, index) => (
          <div className="sui-flex sui-flex-wrap sui-w-full" key={`details-${index}`}>
            <div className="sui-mb-4 sui-w-full">
              <Typography variant="h4">
                {attrGroupList.specTitle}
              </Typography>
            </div>
            <AttributeContent
              attributeGroupList={attrGroupList}
              oneColumn={oneColumn}
              isWhiteSpecTitle={isWhiteSpecTitle}
            />
          </div>
        ))}
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.WARRANTY)
        .map((attrGroupList, index) => (
          <div className="sui-flex sui-flex-wrap sui-w-full" key={`warranty-${index}`}>
            <div className="sui-mb-4 sui-w-full">
              <Typography variant="h4">
                {attrGroupList.specTitle}
              </Typography>
            </div>
            <AttributeContent
              attributeGroupList={attrGroupList}
              oneColumn={oneColumn}
              isWhiteSpecTitle={isWhiteSpecTitle}
            />
          </div>
        ))}
      <div
        id="thdFeedbackPip"
        className="sui-w-full sui-underline empty:sui-hidden"
        suppressHydrationWarning
      />
    </>
  );
};

SpecificationGroupContent.displayName = 'SpecificationGroupContent';

SpecificationGroupContent.propTypes = {
  oneColumn: PropTypes.bool,
  specificationGroup: PropTypes.arrayOf(PropTypes.shape({})),
  returnableStatus: PropTypes.bool,
  isWhiteSpecTitle: PropTypes.bool
};

SpecificationGroupContent.defaultProps = {
  oneColumn: false,
  specificationGroup: null,
  returnableStatus: false,
  isWhiteSpecTitle: false
};
