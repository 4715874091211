export const notEmptyString = (text) => {
  const regex = /^\s*$/;
  return !regex.test(String(text).toLowerCase());
};

export const validQA = (text) => {
  const regex = /^(.|\s){4,255}$/;
  return regex.test(String(text).toLowerCase());
};

export const validateQA = (text) => {
  return notEmptyString(text) && validQA(text);
};

export const validateDisplayName = (text) => {
  const hasValidCharacters = (/^[a-z0-9]+$/).test(String(text).toLowerCase());
  const meetsLengthRequirements = text.length > 3;
  const isValidDisplayName = hasValidCharacters && meetsLengthRequirements;

  let displayNameMessage;
  switch (false) {
  case notEmptyString(text):
    displayNameMessage = 'Please provide a display name.';
    break;
  case hasValidCharacters:
    displayNameMessage = 'We only allow letters (a-z) and numbers.';
    break;
  case meetsLengthRequirements:
    displayNameMessage = 'Display name should have at least 4 characters.';
    break;
  default:
    displayNameMessage = '';
    break;
  }

  return { isValidDisplayName, displayNameMessage };
};

/* eslint-disable */
export const validEmail = (text) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(text).toLowerCase());
};

export const validateEmail = (text) => {
  return notEmptyString(text) && validEmail(text);
};
