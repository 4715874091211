export const cleanString = (str) => {
  return str.replace(/ /g, '').toLowerCase();
};

// As of now, supported only for strings equality check
export const checkEquality = (str1, str2) => {
  let newStr1 = str1;
  let newStr2 = str2;

  if (!newStr1 || !newStr2) {
    return false;
  }
  if (typeof newStr1 !== 'string') {
    newStr1 = newStr1.toString();
  }
  if (typeof newStr2 !== 'string') {
    newStr2 = newStr2.toString();
  }
  return cleanString(newStr1) === cleanString(newStr2);
};

export const isSafari = () => {
  if (!global?.navigator) return false;

  return global?.navigator?.vendor && global?.navigator?.vendor.indexOf('Apple') > -1
      && global?.navigator?.userAgent
      && global?.navigator?.userAgent.indexOf('CriOS') === -1
      && global?.navigator?.userAgent.indexOf('FxiOS') === -1;
};