import React from 'react';
import { func, bool, string, shape } from 'prop-types';
import { Image, Overlay } from '@thd-olt-component-react/core-ui';
import './OverlayPage.scss';

export const OverlayPage = ({
  overlayPageTitle,
  OverlayPageContent,
  overlayPageOpen,
  closeOverlayPage,
  innerOverlay,
}) => {
  return (
    <Overlay
      open={overlayPageOpen}
      onClose={closeOverlayPage}
      className={`overlay-page ${innerOverlay ? 'overlay-page--inner-overlay' : ''} qna-overlay`}
    >
      <header className="overlay-page-header">
        <h2>{overlayPageTitle}</h2>
        <button type="button" onClick={closeOverlayPage} className="close-overlay-page-button">
          <Image
            src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
            alt="Close"
          />
        </button>
      </header>

      {OverlayPageContent}
    </Overlay>
  );
};

OverlayPage.displayName = 'OverlayPage';

OverlayPage.propTypes = {
  overlayPageTitle: string,
  OverlayPageContent: shape({}),
  overlayPageOpen: bool,
  closeOverlayPage: func,
  innerOverlay: bool,
};

OverlayPage.defaultProps = {
  overlayPageTitle: '',
  OverlayPageContent: {},
  overlayPageOpen: false,
  closeOverlayPage: null,
  innerOverlay: false,
};
