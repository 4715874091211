import React from 'react';
import { string, func } from 'prop-types';
import { Typography, Alert } from '@one-thd/sui-atomic-components';

export const SwapToaster = ({
  itemId,
  swapBack,
  clearSwap
}) => (
  <Alert
    status="success"
    className="sui-border-1 sui-border-solid sui-border-strong sui-rounded-base"
    onClose={() => clearSwap(itemId)}
  >
    <div className="sui-flex sui-gap-4 sui-w-full">
      <div className="sui-flex-1">
        <Typography variant="body-base">Swap Successful</Typography>
        <button
          type="button"
          className="sui-mt-2 sui-text-base sui-underline"
          onClick={() => swapBack(itemId)}
        >
          Swap Back
        </button>
      </div>
    </div>
  </Alert>
);

SwapToaster.propTypes = {
  itemId: string.isRequired,
  swapBack: func.isRequired,
  clearSwap: func.isRequired
};