import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { SharebeltContext } from '../../Sharebelt.context';

const ShareBeltFavorite = ({
  disable,
  showFavoritesCount,
  showCircle
}) => {
  const { itemId } = useContext(SharebeltContext);

  if (disable) {
    return null;
  }

  return (
    <AddToList
      itemId={itemId}
      showCount={showFavoritesCount}
      showIconButton={showCircle}
    />
  );
};

ShareBeltFavorite.displayName = 'ShareBeltFavorite';

ShareBeltFavorite.propTypes = {
  disable: PropTypes.bool,
  showFavoritesCount: PropTypes.bool,
  showCircle: PropTypes.bool
};
ShareBeltFavorite.defaultProps = {
  disable: false,
  showFavoritesCount: false,
  showCircle: false
};

ShareBeltFavorite.dataModel = extend({}, AddToList);

export default ShareBeltFavorite;