import React, { useEffect } from 'react';
import { shape as shapeProp, arrayOf as propTypeArrayOf } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  params, string, arrayOf, bool, shape, extend
} from '@thd-nucleus/data-sources';
import { Delivery, DeliveryExpress, InStorePickup } from '@one-thd/sui-icons';
import { getFulfillmentInformationSummary } from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {Product[]} products Array of products
 */

/**
 * Returns a component, BundleSummary, showing the total price and
 * discounts applied to the bundle.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

export const BundleFulfillment = ({ products }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-fulfillment.ready');
  }, []);

  /** @type {object} */
  const { isPickUp, isDelivery, isScheduled } = getFulfillmentInformationSummary(products);

  if (!(isPickUp || isDelivery || isScheduled)) {
    return null;
  }

  return (
    <div
      className="sui-flex sui-flex-col md:sui-flex-row lg:sui-flex-col"
      data-component="BundleFulfillment"
      data-testid="bundle-fulfillment"
    >
      <Typography variant="body-base" weight="bold">Options Available in Cart:</Typography>
      <div className="sui-flex sui-flex-col md:sui-flex-row md:sui-pl-4 sui-pt-4 md:sui-pt-0 lg:sui-pt-4 lg:sui-pl-0">
        {isPickUp && (
          <div className="sui-flex sui-pb-4 md:sui-pb-0 sui-items-center sui-pr-6">
            <div className="sui-flex sui-items-center sui-pr-1">
              <InStorePickup size="small" />
            </div>
            <Typography variant="body-base">Pickup</Typography>
          </div>
        )}
        {isDelivery && (
          <div className="sui-flex sui-pb-4 md:sui-pb-0 sui-items-center sui-pr-6">
            <div className="sui-flex sui-items-center sui-pr-1">
              <Delivery size="small" />
            </div>
            <Typography variant="body-base">Delivery</Typography>
          </div>
        )}
        {isScheduled && (
          <div className="sui-flex sui-items-center">
            <div className="sui-flex sui-items-center sui-pr-1">
              <DeliveryExpress size="small" />
            </div>
            <Typography variant="body-base">Scheduled Delivery</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

BundleFulfillment.propTypes = {
  products: propTypeArrayOf(shapeProp({
    fulfillment: shapeProp()
  })).isRequired
};

BundleFulfillment.dataModel = extend(
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      fulfillment: params({ storeId: string() }).shape({
        fulfillmentOptions: shape({
          fulfillable: bool(),
          services: shape({
            type: string()
          }),
          type: string()
        })
      })
    })
  }
);

BundleFulfillment.displayName = 'BundleFulfillment';