import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { OUT_OF_STOCK, NOT_AVAILABLE } from '../../constants';

export const BundleMessage = ({ messageRequest }) => {
  if (messageRequest === NOT_AVAILABLE) {
    return (
      <div data-component="FulfillmentNotifyMe">
        <Typography variant="h4" weight="display">Not Available</Typography>
      </div>
    );
  }

  if (messageRequest === OUT_OF_STOCK) {
    return (
      <div data-component="FulfillmentNotifyMe">
        <Typography variant="h4" weight="display">Out of Stock</Typography>
        <div className="sui-w-72 sui-mt-1" data-component="TrackOutOfStock">
          <Typography variant="body-base">
            One or more of these items is currently unavailable online and in the stores.
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};

BundleMessage.propTypes = {
  messageRequest: PropTypes.string
};

BundleMessage.defaultProps = {
  messageRequest: null
};
