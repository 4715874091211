import React from 'react';
import { string, func } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { SwapItem } from '@thd-olt-component-react/swap-item';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { PLACEHOLDER_IMAGE } from '../../constants';
import './placeholder-image.scss';

const placeholderElement = (
  <div data-testid="placeholder-img">
    <Image src={PLACEHOLDER_IMAGE} alt="add product" lazy={false} />
  </div>
);

export const PlaceholderImage = ({ itemId, productGroupLabel, handlePlaceholderClick }) => {
  const handleAddNewItem = (newProduct) => {
    handlePlaceholderClick(newProduct, productGroupLabel);
  };

  return (
    <QueryProvider
      cacheKey="dig-empty-container"
    >
      <SwapItem
        itemId={itemId}
        onSwap={handleAddNewItem}
        closeOnSwap
        className="unique-placeholder-image"
        selectionButtonContent="Select This Item"
        currentItemSelectable
        customElement={placeholderElement}
      />
    </QueryProvider>
  );
};

PlaceholderImage.propTypes = {
  itemId: string.isRequired,
  productGroupLabel: string.isRequired,
  handlePlaceholderClick: func.isRequired
};