import React, { useContext, useMemo } from 'react';
import { number } from 'prop-types';
import { Pagination } from '@thd-olt-component-react/pagination';
import { useDataModel, params, string as stringType, shape as shapeType } from '@thd-nucleus/data-sources';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import {
  QUESTION_DISPLAY_MOBILE,
  QUESTION_PER_PAGE_DESKTOP,
  QUESTION_ON_PAGE_LOAD_DESKTOP,
  DEFAULT_NUMBER_OF_PAGES,
  BASE_10
} from '../../constants';
import { getDesktopPage2StartIndex } from '../../helpers/QAHelper';
import './Pager.scss';

export const Pager = ({ seoTotalResults }) => {
  const {
    channel, currentPage = 1, dispatch, TotalResults, itemId, enableExperienceProperty
  } = useContext(QAContext);

  const results = TotalResults || seoTotalResults;
  const totalQuestionsPages = Math.floor(results / QUESTION_ON_PAGE_LOAD_DESKTOP);
  const { data } = useDataModel('product', { variables: { itemId } });
  const seoLink = data?.product?.identifiers?.canonicalUrl?.replace('/p', '/p/questions');

  const isMobile = channel === 'mobile';
  const desktopPage2StartIndex = getDesktopPage2StartIndex(enableExperienceProperty);
  const totalPages = useMemo(() => {
    if (results) {
      if ((isMobile && results <= QUESTION_DISPLAY_MOBILE)
        || (!isMobile && results <= QUESTION_ON_PAGE_LOAD_DESKTOP)) {
        return DEFAULT_NUMBER_OF_PAGES;
      }
      if (isMobile && results > QUESTION_DISPLAY_MOBILE) {
        // mobile
        return results % QUESTION_DISPLAY_MOBILE === 0
          ? parseInt(results / QUESTION_DISPLAY_MOBILE, BASE_10)
          : DEFAULT_NUMBER_OF_PAGES + parseInt(results / QUESTION_DISPLAY_MOBILE, BASE_10);
      }
      if (!isMobile && results > QUESTION_ON_PAGE_LOAD_DESKTOP) {
        // Desktop
        if (results <= QUESTION_PER_PAGE_DESKTOP) {
          // qn num - 4 to 20
          return 2;
        }
        // qn num 20 onwards...
        return 2 + parseInt((results - desktopPage2StartIndex) / QUESTION_PER_PAGE_DESKTOP, BASE_10);
      }
      return DEFAULT_NUMBER_OF_PAGES;
    }
    // default
    return DEFAULT_NUMBER_OF_PAGES;
  }, [TotalResults]);

  const scrollToQASection = () => {
    const el = document.querySelector('.questions-and-answers');
    if (el) {
      document.querySelector('.questions-and-answers').scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handlePageChange = (newPageNumber) => {
    dispatch({ action: 'SET_CURRENT_PAGE', value: newPageNumber });

    if (newPageNumber === DEFAULT_NUMBER_OF_PAGES) {
      dispatch({ action: 'SET_START_INDEX', value: DEFAULT_NUMBER_OF_PAGES });
    } else {
      const newStartIndex = !isMobile ? desktopPage2StartIndex + (newPageNumber - 2) * QUESTION_PER_PAGE_DESKTOP
        : QUESTION_DISPLAY_MOBILE * (newPageNumber - DEFAULT_NUMBER_OF_PAGES) + DEFAULT_NUMBER_OF_PAGES;
      dispatch({ action: 'SET_START_INDEX', value: newStartIndex });
    }

    const payload = { component: 'pagination' };
    triggerAnalytics(payload);
  };

  return (
    <Pagination
      currentPage={currentPage}
      onPageChanged={(pageNumber) => {
        handlePageChange(pageNumber);
        scrollToQASection();
      }}
      showArrows
      totalPages={totalPages || 1}
      href="?"
      totalReviewPages={totalQuestionsPages}
      pageReviewLimit={totalQuestionsPages}
      noQueryParams
      queryHandler={(name, value) => {
        return {
          name: '',
          value: `${seoLink}/${value}`
        };
      }}
    />
  );
};

Pager.displayName = 'Pager';

Pager.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    identifiers: shapeType({
      canonicalUrl: stringType(),
    }),
  }),
};

Pager.propTypes = {
  seoTotalResults: number
};

Pager.defaultProps = {
  seoTotalResults: null
};
