import React from 'react';
import { bool, string, shape } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { Sharebelt } from '@thd-olt-component-react/thd-sharebelt';
import { BundleShare } from './BundleShare';
import { ratingToggleFromProductOverview } from '../utils/product-bundle-utils';
/**
 * Props
 *
 * @typedef {object} Props
 * @property {itemId} itemId Item Id
 * @property {hideRatingsIfHDPPSku} hideRatingsIfHDPPSku hideRatingsIfHDPPSku
 * @property {target} target target accordion to expand
 * @property {offsetselector} offsetselector offsetselector
 * @property {{bundleSpecificationDetails: object}} product Bundle product
 * @property {qaselector} qaselector Id of question and answer accordion
 */

/**
 * Returns a component, Bundle Product Overview
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

export const BundleProductOverview = ({
  product,
  itemId,
  hideRatingsIfHDPPSku
}) => {

  const { starRatingProductOverview } = product?.features ?? {};

  const handleRatingClick = (accordionId) => {
    ratingToggleFromProductOverview({ accordionId });
  };

  return (
    <div data-component="BundleProductOverview" data-testid="BundleProductOverview">
      <ProductDetails itemId={itemId}>
        <ProductDetails.Badge itemId={itemId} />
        <ProductDetails.BrandCollection
          showBrandHyperLink
          hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
        />
        <div className="sui-flex sui-items-start sui-flex-col sui-w-5/6">
          <ProductDetails.Title />
        </div>
        <div className="sui-absolute sui-right-1 sui-top-0">
          <Sharebelt itemId={itemId}>
            <BundleShare itemId={itemId} showCircle showFavoritesCount />
          </Sharebelt>
        </div>
        {starRatingProductOverview && (
          <div data-testid="BundleProductDetailsRatings">
            <ProductDetails.Ratings
              showBadge
              target="#product-section-rr"
              offsetselector="#sticky-nav"
              qaselector="#product-section-qa"
              onClick={(event) => handleRatingClick(event)}
              hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
            />
          </div>
        )}
      </ProductDetails>
    </div>
  );
};

BundleProductOverview.propTypes = {
  hideRatingsIfHDPPSku: bool,
  product: shape({
    bundleSpecificationDetails: shape({
      type: string
    })
  }),
  itemId: string.isRequired
};

BundleProductOverview.defaultProps = {
  hideRatingsIfHDPPSku: false,
  product: undefined
};

BundleProductOverview.dataModel = extend(ProductDetails, Sharebelt);

BundleProductOverview.displayName = 'BundleProductOverview';
