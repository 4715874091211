import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@one-thd/sui-atomic-components';
import { Price } from '@thd-olt-component-react/price';
import { CustomSkeletonLine } from './CustomSkeletonLine';
import { EmptyContainer } from './EmptyContainer';
import { RemoveDrawer } from './RemoveDrawer';
import { SwapToaster } from './SwapToaster';
import { SwapProductLoader } from './SwapProductLoader';
import { getIsMobile, trackAnalytics } from '../../utils/product-bundle-utils';
import { QuickViewLazy } from './QuickViewLazy';
import '../../styles/productListContent.scss';
import { ProductListImage } from './ProductListImage';
import { ProductListDetails } from './ProductListDetails';
import { SwapOptions } from './SwapOptions';
/**
 * Props
 *
 * @typedef {object} Props
 * @property {{bundleSpecificationDetails: {type: string}}} product Bundle product
 * @property {array} products - The number of products in the bundle
 * @property {string} channel - If it is Desktop or Mobile
 */

/**
 * Returns a formated Product List of the provided products.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const ProductListContent = ({
  products,
  product: bundleProduct,
  channel,
  accordionName,
  scrollStickyHeaderHeight,
  addProduct,
  removeProduct,
  swapProducts,
  swapBack,
  clearSwap,
  loading
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const isMobile = getIsMobile(channel);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const { swapToaster } = bundleProduct?.features ?? {};
  const openQuickView = (product) => {
    if (!isQuickViewOpen) {
      setIsQuickViewOpen(true);
      setSelectedProduct(product);
    }
  };

  const triggerAnalytics = (index) => {
    const analyticsData = {
      position: `${index + 1}`
    };
    trackAnalytics('remove-item.success', analyticsData);
  };

  return (
    <ul data-testid="product-list-content" className="sui-w-full">
      {products?.length > 0
        ? (
          <>
            {
              products?.map((product, index) => {

                return (
                  <React.Fragment key={product?.itemId || index}>
                    <li
                      key={product?.itemId}
                      className="product-list-item sui-flex sui-flex-col sui-gap-4"
                      data-testid="product-list-item"
                    >
                      {product?.removed ? (
                        <EmptyContainer
                          product={product}
                          loading={loading && selectedItemId === product.itemId}
                          onClick={addProduct}
                          setSelectedItemId={setSelectedItemId}
                          isMobile={isMobile}
                        />
                      )
                        : (
                          <React.Fragment key={`product-content-${product.itemId || index}`}>
                            {
                              swapToaster && product?.swappedProduct && (
                                <SwapToaster
                                  itemId={product?.itemId}
                                  swapBack={swapBack}
                                  clearSwap={clearSwap}
                                />
                              )
                            }
                            <div className="sui-w-full">
                              {loading && selectedItemId === product.itemId ? (
                                <SwapProductLoader key={`removed-${product.itemId}`} />
                              ) : (
                                <div className="sui-flex sui-gap-4" key={`product-${product.itemId}`}>
                                  <ProductListImage
                                    product={product}
                                    openQuickView={openQuickView}
                                  />
                                  {/* eslint-disable tailwindcss/no-arbitrary-value */ }
                                  {/* eslint-disable-next-line max-len */}
                                  <div className="sui-flex sui-flex-col sui-gap-2 sui-flex-1 md:sui-grid md:sui-grid-cols-[1fr_160px] md:sui-gap-4">
                                    <ProductListDetails
                                      product={product}
                                      openQuickView={openQuickView}
                                      channel={channel}
                                      accordionName={accordionName}
                                      setIsRatingOpen={setIsRatingOpen}
                                      scrollStickyHeaderHeight={scrollStickyHeaderHeight}
                                      isMobile={isMobile}
                                    />
                                    <Price
                                      data-testid="product-list-item-price"
                                      channel={channel}
                                      itemId={product?.itemId}
                                      large={false}
                                      hideLimitPerOrder
                                      displayEachUom={false}
                                      clsRemediation={{
                                        placeholders: true,
                                        preservePlaceholders: true
                                      }}
                                      product={{
                                        itemId: product.itemId,
                                        identifiers: { itemId: product.itemId },
                                        pricing: {
                                          ...product?.pricing
                                        }
                                      }}
                                    />
                                    <SwapOptions
                                      product={product}
                                      setSelectedItemId={setSelectedItemId}
                                      swapProducts={swapProducts}
                                      index={index}
                                      triggerAnalytics={triggerAnalytics}
                                      setIsDrawerOpen={setIsDrawerOpen}
                                      isDrawerOpen={isDrawerOpen}
                                      isMobile={isMobile}
                                    />

                                  </div>
                                </div>
                              )}
                            </div>
                          </React.Fragment>
                        )}
                    </li>
                    <hr
                      key={`separater-${product?.itemId || index}`}
                      className="sui-my-4 sui-w-full sui-h-0 sui-border-primary last-of-type:sui-hidden"
                    />
                  </React.Fragment>
                );
              })
            }
            {typeof window !== 'undefined' && isQuickViewOpen && (
              <Suspense fallback={<div />}>
                <QuickViewLazy
                  isOpen={isQuickViewOpen}
                  onClose={() => {
                    setIsQuickViewOpen(false);
                    setSelectedProduct(null);
                    setIsRatingOpen(false);
                  }}
                  fetch={isQuickViewOpen}
                  itemId={selectedProduct?.itemId}
                  isRatingsOpen={isRatingOpen}
                  paginateCarousel={false}
                  openDetailsInNewTab
                />
              </Suspense>
            )}
          </>
        )
        : [...Array(5).keys()].map((item) => {
          return (
            <li
              key={item}
              // eslint-disable-next-line max-len
              className="product-list-item first:sui-pt-0 last:sui-pb-0 last:sui-border-0 sui-flex sui-items-start sui-border-b-1 sui-border-primary sui-border-solid sui-py-4"
              data-testid="product-list-item"
            >
              <div className="sui-w-full" data-testid="skeleton-loader">
                <Skeleton grow disableGutters disablePadding>
                  <CustomSkeletonLine height="80px" />
                </Skeleton>
              </div>
            </li>
          );
        })}
      {isDrawerOpen && (
        <RemoveDrawer
          closeDrawer={() => setIsDrawerOpen(false)}
          itemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
          isDrawerOpen={isDrawerOpen}
          storeId="121"
          products={products}
          removeProduct={removeProduct}
          swapProducts={swapProducts}
          loading={loading}
          isMobile={isMobile}
        />
      )}
    </ul>
  );
};

ProductListContent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      dataSource: PropTypes.string,
      reviews: PropTypes.shape({
        ratingsReviews: PropTypes.shape({
          averageRating: PropTypes.string,
          totalReviews: PropTypes.string
        })
      }),
      identifiers: PropTypes.shape({
        brandName: PropTypes.string,
        productLabel: PropTypes.string,
        modelNumber: PropTypes.string
      }),
      media: PropTypes.shape({
        images: PropTypes.arrayOf(
          PropTypes.shape({
            sizes: PropTypes.arrayOf(PropTypes.string),
            url: PropTypes.string
          })
        )
      })
    })
  ),
  product: PropTypes.shape({
    bundleSpecificationDetails: PropTypes.shape({
      type: PropTypes.string
    }),
    features: PropTypes.shape({
      swapToaster: PropTypes.bool
    })
  }),
  channel: PropTypes.string.isRequired,
  accordionName: PropTypes.string,
  scrollStickyHeaderHeight: PropTypes.number.isRequired,
  addProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  swapProducts: PropTypes.func.isRequired,
  swapBack: PropTypes.func.isRequired,
  clearSwap: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

ProductListContent.defaultProps = {
  products: undefined,
  product: undefined,
  accordionName: null
};

ProductListContent.displayName = 'ProductListContent';

export { ProductListContent };