import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter
} from '@one-thd/sui-atomic-components';
import { CARD_IMAGE } from '../utils/constants';

const TermsAndConditionsDrawer = (
  {
    applyNowUrl,
    closeDrawer,
    open,
    showFinancing,
    financingMonths,
    promoStartDate,
    promoEndDate,
    applyNowClick,
    isB2B,
    isAppliancePromo,
    isTieredPromo,
    paymentEstimatorLoaded
  }) => {

  const [discountEndDate, setDiscountEndDate] = useState('');
  const [interestRate, setInterestRate] = useState('');

  const getDiscountEndDate = () => {
    if (window?.THD?.PaymentEstimator?.defaultPromoEndDate
      && typeof window?.THD?.PaymentEstimator?.defaultPromoEndDate === 'function'
    ) {
      return window?.THD?.PaymentEstimator?.defaultPromoEndDate();
    }
    return '';
  };

  const getInterestRate = () => {
    if (window?.THD?.PaymentEstimator?.interestRate && typeof window?.THD?.PaymentEstimator?.interestRate === 'function'
    ) {
      return window?.THD?.PaymentEstimator?.interestRate();
    }
    return '';
  };

  useEffect(() => {
    if (paymentEstimatorLoaded) {
      setDiscountEndDate(getDiscountEndDate());
      setInterestRate(getInterestRate());
    }
  }, [paymentEstimatorLoaded]);

  const discountDetails = () => {
    const financingValue = `${financingMonths}-months ${isAppliancePromo || isTieredPromo ? 'special' : 'everyday'}`;
    let qualifyingWord = '';
    if (isAppliancePromo) {
      qualifyingWord = 'Appliance ';
    } else if (isTieredPromo) {
      qualifyingWord = 'qualifying ';
    }
    return (
      <Typography variant="body-base">
        Get<span className="sui-font-bold"> $25 off</span> purchases of $25-$299*,
        <span className="sui-font-bold"> $50 off</span> purchases of $300-999*
        or <span className="sui-font-bold">$100 off</span> purchases of $1000+*
        on your next qualifying purchase when you open a new card.
        {showFinancing && (
          <>
            <span className="sui-font-bold"> Plus, receive {financingValue} financing† </span>
            on {qualifyingWord}purchases $299 or more when you use your Consumer Card.
          </>
        )}
      </Typography>
    );
  };

  const financingDetails = () => {
    const boldText = isTieredPromo ? 'PROMOTIONAL PERIOD: ' : (financingMonths + ' MONTHS†');
    let promoDetailsText = `on ${isAppliancePromo ? 'appliance ' : ''}purchases of $299 or more \
     made on The Home Depot Consumer Credit Card.`;
    if (isTieredPromo) {
      promoDetailsText = '12 Months† on Purchases of $299–$998, 18 Months† on Purchases of'
        + ' $999–$1,998 or 24 Months† on Purchases of $1,999 or More.';
    }
    const endText = isTieredPromo ? 'the promotional period' : financingMonths + ' months';
    const promoDateRange = promoStartDate && promoEndDate ? `Valid ${promoStartDate} - ${promoEndDate}` : '';
    return (
      <Typography variant="body-base">
        <span className="sui-font-bold">NO INTEREST IF PAID IN FULL WITHIN {boldText} </span>
        {promoDetailsText} Interest will be charged to your account from the purchase date if the
        purchase balance (including premiums for optional credit insurance) is not paid in full
        within {endText}. {promoDateRange}
      </Typography>
    );
  };

  const financingTerms = () => {
    const interestRateText = interestRate ? ` APR: ${interestRate}. ` : '';
    return (
      <div className="sui-border-button-inactive sui-border-solid sui-border-1 sui-p-2 sui-mb-4">
        <Typography variant="body-xs">
          †With credit approval for qualifying purchases made on The Home Depot Consumer Credit
          Card. {interestRateText} Minimum interest charge: $2. See card agreement
          for details including APR applicable to you. Offer is only valid for consumer accounts;
          6 months everyday credit offer is subject to change without notice; see store for details.
        </Typography>
      </div>
    );
  };

  const discountTerms = () => {
    const validThrough = discountEndDate ? ` and valid now through ${discountEndDate}. ` : '. ';
    return (
      <ul className="sui-flex sui-flex-col sui-list-none">
        <li className="sui-flex sui-gap-2px">
          <Typography variant="body-base" weight="bold">*&nbsp;</Typography>
          <Typography variant="body-base">
            <span className="sui-font-bold">Offer is for new accounts and subject to credit approval
              {validThrough}
            </span>
            This offer is redeemable for either a $25 discount on a single-receipt purchase of $25
            up to $299, a $50 discount on a single-receipt purchase of $300 up to $999 or a $100
            discount on a single-receipt purchase of $1,000 or more, excluding tax and delivery
            fees, within 30 days of your account open date at The Home Depot® retail stores or
            homedepot.com when made with your The Home Depot Consumer Credit Card, The Home Depot
            Home Improver Card, the Pro Xtra Credit Card, or The Home Depot Commercial Account.
            Valid in the U.S., U.S.V.I., Puerto Rico and Guam. Offer does not apply to prior
            purchases, The Home Depot Gift Cards or Certificates, third party gift cards,
            installation products or services purchased in home, Tool Rental or to Traeger®, Weber®,
            Bona®, Simplehuman®, Dacor®, Viking®, Fisher & Paykel®, Sharp InsightTM, Delonghi®,
            Buckhaven, Lynx®, AlfrescoTM, OCI, Marvel®, Bertazzoni, Aga, Vent-A-Hood®, Samsung Chef
            Collection, Bosch Benchmark® Series, Liebherr, Zephyr, Miele, Signature Kitchen Suite,
            Jenn-Air®, Thermador®, Jeld-Wen® Door Systems, Monogram products and trade styles.
            Additional exclusions may apply which will be reflected at checkout. May not be combined
            with other discounts; ask an Associate for details.
          </Typography>
        </li>
      </ul>
    );
  };

  return (
    <>
      { paymentEstimatorLoaded ? (
        <Drawer
          open={open}
          onClose={closeDrawer}
          position="right"
          id="drawer-terms-conditions"
        >
          <DrawerHeader title="Enjoy Special Savings with your new Account" onClose={closeDrawer} />
          <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
            <DrawerBody>
              <div className="sui-flex sui-flex-col sui-gap-4">
                <div className="sui-m-auto sui-px-6 sui-max-w-xs">
                  <img
                    src={isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT}
                    alt="Credit Card Icon"
                    width="185"
                    height="103"
                  />
                </div>
                <div className="sui-flex sui-flex-col sui-gap-3">
                  {discountDetails()}
                  {showFinancing && financingDetails()}
                </div>
                <div className="sui-grow">
                  {showFinancing && financingTerms()}
                  {discountTerms()}
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="primary"
                data-testid="drawer-apply-now"
                href={applyNowUrl}
                onClick={applyNowClick}
                fullWidth
              >
                Apply Now
              </Button>
            </DrawerFooter>
          </div>
        </Drawer>
      ) : null}
    </>

  );
};

TermsAndConditionsDrawer.propTypes = {
  applyNowUrl: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showFinancing: PropTypes.bool,
  financingMonths: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  promoStartDate: PropTypes.string,
  promoEndDate: PropTypes.string,
  applyNowClick: PropTypes.func, // Replace with analytics built in?
  isB2B: PropTypes.bool,
  isAppliancePromo: PropTypes.bool,
  isTieredPromo: PropTypes.bool,
  paymentEstimatorLoaded: PropTypes.bool.isRequired
};

TermsAndConditionsDrawer.defaultProps = {
  open: true,
  applyNowClick: () => { },
  isB2B: false,
  showFinancing: false,
  financingMonths: 6,
  promoStartDate: null,
  promoEndDate: null,
  isAppliancePromo: false,
  isTieredPromo: false,
};

export default TermsAndConditionsDrawer;
